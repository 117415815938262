import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class ProductThumbnail extends Component {
    render() {
        let {id, name, image1, size, quality} = this.props.product;
        const category = this.props.category;
        const mname = this.props.mname;
        const cid = this.props.cid;
        const color = this.props.color;
        let link = '';
        console.log(category, mname, cid, color);
        if(category=== 'Tiles') {
            link = `/product/${category}/${mname}/${cid}/${color}/${id}` || '';
        }else{
            link = `/product/${category}/${mname}/${cid}/${id}` || '';
        }

        console.log(link);
        quality = quality || '';

        return (
            <div className="col-md-4">
                <div className="card mb-4 shadow-sm">
                    <Link to={link}>
                        <img className="img-fluid img-thumbnail"
                             alt={name}
                             src={"https://lh3.googleusercontent.com/d/" + image1}
                             title="Click for the larger version."
                        />
                        <div className="card-body">
                            <p className="card-text">{name}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                {/*<div className="btn-group"/>*/}
                                <small className="font-weight-bold blue-text">{quality.toUpperCase()}</small>
                                <small className="text-muted">{size}</small>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}