import React, {Component} from "react";
import Redirect from "react-router-dom/Redirect";

export default class DeleteProduct extends Component {
    state = {
        loading: true,
        manufacturers: [],
        products: [],
        collections: [],
        mname: "",
        selectedManufacturer: "",
        product: [
            {
                id: "",
                pid: "",
                name: "",
                collection: "",
                description: "",
                technology: "",
                thickness: "",
                colour: "",
                size: "",
                room: "",
                dateCreated: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                technicalDrawing: ""
            }
        ],
        productDefault: [
            {
                id: "",
                pid: "",
                name: "",
                collection: "",
                description: "",
                technology: "",
                thickness: "",
                colour: "",
                size: "",
                room: "",
                dateCreated: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                technicalDrawing: ""
            }
        ],
        exists: false,
        buttonDisabled: false,
        btnStyle: "btn btn-md btn-success",
    };

    selectDefault() {
        console.log("default passed");
        let manufacturerSelect = document.getElementById("manufacturerSelect");
        manufacturerSelect.value = "";
        let collectionSelect = document.getElementById("collection");
        collectionSelect.value = "";
        let pidSelect = document.getElementById("pid");
        pidSelect.value = "";
        console.log(pidSelect.value);
    }

    async componentDidMount() {
        const url2 = `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response2 = await fetch(url2);
        const data2 = await response2.json();
        this.setState({categories: data2, loading: false})
    }

    getProduct() {
        if (this.refs.product.value === "") return;
        this.setState({
            product: this.state.products.filter(
                product => product.id === this.refs.product.value
            )
        });
        console.log(this.state.product[0]);
    }

    updateManufacturer() {
        if (this.refs.manufacturer.value === "") return;
        this.setState({mname: this.refs.manufacturer.value});
        this.setState({product: this.state.productDefault});
        //this.refs.product.value = "";
        console.log(this.state.product[0]);
    }

    handleInputChange(e) {
        this.setState({product: [{...this.state.product[0], [e.target.name]: e.target.value}]});
        console.log(this.state.product);
    }

    handleChange(e) {
        let checkId = e.target.value;
        if (e.target.name === "category") {
            this.setState({selectedCategory: e.target.value });
            console.log(checkId);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    console.log("test:" + data);
                    if (data.length > 0) {
                        this.setState({
                            manufacturers: data,
                            collections: [],
                            products: [],
                            product: this.state.productDefault
                        })
                    } else
                        this.setState({
                            selectedManufacturer: ""
                        })
                });
            console.log(this.state.manufacturers);
            this.selectDefault();
        } else if (e.target.name === "mname") {
            console.log("manufacturer: " + e.target.value);
            let value = e.target.value;
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_manufacturers_category_collections.php?mname=${e.target.value}&category=${this.state.selectedCategory}`)
                .then(response => response.json())
                .then(data => {
                    console.log("collections: " + JSON.stringify(data));
                    if (data.length > 0) {
                        this.setState({
                            collections: data,
                            products: [],
                            product: this.state.productDefault,
                            selectedManufacturer: value
                        })
                    } else {
                        this.setState({
                            collections: [],
                            products: [],
                            product: this.state.productDefault,
                            selectedCollection: ""
                        })
                    }
                })
        } else if (e.target.name === "collection") {
            console.log("collection: " + e.target.value);
            console.log(this.state.selectedManufacturer);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}product/get_collection_products.php?collection=${e.target.value}&mname=${this.state.selectedManufacturer}`)
                .then(response => response.json())
                .then(data => {
                    console.log("products: " + JSON.stringify(data));
                    if (data.length > 0) {
                        this.setState({
                            product: this.state.productDefault,
                            products: data
                        })
                    } else {
                        this.setState({
                            products: [],
                            product: this.state.productDefault,
                        })
                    }
                });
            let pidSelect = document.getElementById("pid");
            pidSelect.value = "";
        } else if (e.target.name === "pid") {
            console.log("pid");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}product/get_product_pid.php?pid='${e.target.value}'`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        this.setState({
                            product: data,
                            // exists: true,
                            // buttonDisabled: true,
                            // btnStyle: 'btn btn-md btn-secondary'
                        })
                    } else {
                        console.log("product not found?");
                        this.setState({
                            exists: false,
                            buttonDisabled: false,
                            btnStyle: 'btn btn-md btn-success'
                        })
                    }
                })
        } else {
            console.log("something is wrong EVENT HANDLER");
        }
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        // const mname = this.state.mname;
        const product = this.state.product[0];
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Delete product</p>

                        <label htmlFor="category" className="grey-text font-weight-light">
                            Category
                        </label>
                        <br/>
                        <select name='category' onChange={this.handleChange.bind(this)}>
                            <option value="" selected disabled hidden>Select Category</option>
                            {
                                this.state.categories.map(m => {
                                    return <option value={m.category} name='category' key={m.category}
                                    >{m.category} </option>
                                })
                            }
                        </select>
                        <br/>
                        <br/>

                        <label htmlFor="mname" className="grey-text font-weight-light">
                            Manufacturer Name
                        </label>
                        <br/>
                        <select
                            id="manufacturerSelect"
                            name="mname"
                            ref="manufacturer"
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="" selected disabled hidden>Choose here</option>
                            {this.state.manufacturers.map(m => {
                                return (
                                    <option value={m.mname} name="mname" key={m.mname} required>
                                        {m.mname}
                                    </option>
                                );
                            })}
                        </select>
                        <br/>
                        <br/>


                        <label htmlFor="collection" className="grey-text font-weight-light">
                            Collection
                        </label>
                        <br/>
                        <select id="collection" name='collection' onChange={this.handleChange.bind(this)}>
                            <option value="" disabled selected>Choose here</option>
                            {
                                this.state.collections.map(m => {
                                    return <option value={m.name} name='name' key={m.name}
                                    >{m.name}</option>
                                })
                            }
                        </select>
                        <br/>
                        <br/>

                        <label htmlFor="pid" className="grey-text font-weight-light">
                            Product ID
                        </label>
                        <br/>
                        <select id="pid" name="pid" ref="pid" onChange={this.handleChange.bind(this)}>
                            <option value="" selected disabled> Choose here</option>
                            {
                                this.state.products.map(product => {
                                    return <option value={product.pid} name='pid' key={product.pid}>
                                        {product.pid}
                                    </option>
                                })
                            })}
                        </select>
                        <br/>
                        <br/>


                        <label
                            htmlFor="name"
                            className="grey-text font-weight-light"
                        >
                            Name
                        </label>
                        <input
                            className="form-control"
                            id="name"
                            name="name"
                            value={product.name}
                            //placeholder="Name"
                            //onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label
                            htmlFor="description"
                            className="grey-text font-weight-light"
                        >
                            Description
                        </label>
                        <input
                            className="form-control"
                            id="description"
                            name="description"
                            value={product.description}
                            //placeholder="Description"
                            //onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label
                            htmlFor="technology"
                            className="grey-text font-weight-light"
                        >
                            Technology
                        </label>
                        <input
                            className="form-control"
                            id="technology"
                            name="technology"
                            value={product.technology}
                            //onChange={this.handleInputChange.bind(this)}
                            //placeholder="Technology"
                            readOnly
                        />
                        <br/>


                        <label
                            htmlFor="thickness"
                            className="grey-text font-weight-light"
                        >
                            Thickness
                        </label>
                        <input
                            className="form-control"
                            id="thickness"
                            name="thickness"
                            value={product.thickness}
                            //onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="colour" className="grey-text font-weight-light">
                            Colour
                        </label>
                        <input
                            className="form-control"
                            id="colour"
                            name="colour"
                            value={product.colour}
                            //onChange={this.handleInputChange.bind(this)}
                            //placeholder="Colour"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="size" className="grey-text font-weight-light">
                            Size
                        </label>
                        <input
                            className="form-control"
                            id="size"
                            name="size"
                            value={product.size}
                            //onChange={this.handleInputChange.bind(this)}
                            //placeholder="size"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="room" className="grey-text font-weight-light">
                            Room
                        </label>
                        <input
                            className="form-control"
                            id="room"
                            name="room"
                            value={product.room}
                            readOnly
                        />
                        <br/>

                        <label htmlFor="quality" className="grey-text font-weight-light">
                            Quality
                        </label>
                        <input
                            className="form-control"
                            id="quality"
                            name="quality"
                            value={product.quality}
                            readOnly
                        />
                        <br/>


                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image1 URL
                        </label>
                        <input
                            className="form-control"
                            id="img1URL"
                            name="image1"
                            value={product.image1}
                            //onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>

                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image2 URL
                        </label>
                        <input
                            className="form-control"
                            id="img2URL"
                            name="image2"
                            value={product.image2}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image3 URL
                        </label>
                        <input
                            className="form-control"
                            id="img3URL"
                            name="image3"
                            value={product.image3}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image4 URL
                        </label>
                        <input
                            className="form-control"
                            id="img4URL"
                            name="image4"
                            value={product.image4}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image5 URL
                        </label>
                        <input
                            className="form-control"
                            id="img5URL"
                            name="image5"
                            value={product.image5}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image6 URL
                        </label>
                        <input
                            className="form-control"
                            id="img6URL"
                            name="imgSource"
                            value={product.image6}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image7 URL
                        </label>
                        <input
                            className="form-control"
                            id="img7URL"
                            name="image7"
                            value={product.image7}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image8 URL
                        </label>
                        <input
                            className="form-control"
                            id="img8URL"
                            name="image8"
                            value={product.image8}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image9 URL
                        </label>
                        <input
                            className="form-control"
                            id="img9URL"
                            name="image9"
                            value={product.image9}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image10 URL
                        </label>
                        <input
                            className="form-control"
                            id="img10URL"
                            name="image10"
                            value={product.image10}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="technicalDrawing" className="grey-text font-weight-light">
                            Technical Drawing URL
                        </label>
                        <input
                            className="form-control"
                            id="technicalDrawing"
                            name="technicalDrawing"
                            value={product.technicalDrawing}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <br/>
                        <form
                            action={process.env.REACT_APP_API_SERVER_IP + "product/delete_product.php"}
                            method="POST"
                        >

                            <input type="hidden" name="id" value={product.id}/>

                            <div className="text-center py-4 mt-3">
                                <button
                                    type="submit"
                                    className={this.state.btnStyle}
                                    disabled={this.state.buttonDisabled}
                                >
                                    Delete Product{" "}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}