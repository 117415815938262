import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";

class Admin extends Component {
    state = {
        redirect: true,
        user: {},
    };

    componentWillMount() {
        if (localStorage.getItem('user')) {
            this.setState({redirect: false});
        }
    }

    logout() {
        localStorage.setItem("user", "");
        localStorage.clear();
        this.setState({redirect: true});
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/login"}/>;
        }
        return (
            <div className="container-fluid notranslate">
                <hr/>
                <div className="row text-center text-lg-center">
                    <div className="col-lg-3 col-md-3 col-xs-6">
                        <Link to="/admin/add-product" className="nav-link">
                            <i className="fa fa-plus-circle" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Add Product</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-6">
                        <Link to="/admin/add-collection" className="nav-link">
                            <i className="fa fa-plus-circle" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Add Collection</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-6">
                        <Link to="/admin/add-category" className="nav-link">
                            <i className="fa fa-plus-circle" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Add Category</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-6">
                        <Link to="/admin/add-project" className="nav-link">
                            <i className="fa fa-plus-circle" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Add Project</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/edit-product" className="nav-link">
                            <i className="fa fa-edit" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Edit Product</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/edit-collection" className="nav-link">
                            <i className="fa fa-edit" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Edit Collection</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/edit-project" className="nav-link">
                            <i className="fa fa-edit" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Edit Project</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/delete-product" className="nav-link">
                            <i className="fa fa-trash" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Delete Product</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/delete-collection" className="nav-link">
                            <i className="fa fa-trash" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Delete Collection</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/delete-manufacturer" className="nav-link">
                            <i className="fa fa-trash" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Delete Man/Category</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/delete-project" className="nav-link">
                            <i className="fa fa-trash" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Delete Project</div>
                            </i>
                        </Link>
                    </div>
                    {/*<div className="col-lg-3 col-md-4 col-xs-6">*/}
                    {/*    <button onClick={this.logout.bind(this)} className="logout nav-link">*/}
                    {/*        <i className="fa fa-sign-out" style={this.iconStyles}>*/}
                    {/*            <div style={{fontSize: "45px", color: "#0000FF"}}>*/}
                    {/*                Sign Out*/}
                    {/*            </div>*/}
                    {/*        </i>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/remove-administrator" className="nav-link">
                            <i className="fa fa-user" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Remove Admin</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <Link to="/admin/add-administrator" className="nav-link">
                            <i className="fa fa-user-plus" style={this.iconStyles}>
                                <div style={{fontSize: "25px"}}>Add Admin</div>
                            </i>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <button onClick={this.logout.bind(this)}>
                            <i className="fa fa-sign-out logout nav-link"
                               style={this.iconStyles}
                               onClick={this.logout.bind(this)}
                            >
                                <div style={{fontSize: "25px", color: "#0000FF"}}>Sign Out</div>
                            </i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    iconStyles = {
        fontSize: "120px",
        color: "black"
    };
}

export default Admin;
