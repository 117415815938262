import React, {Component} from "react";
import CollectionThumbnail from "./CollectionThumbnail";

export default class ManufacturerCollections extends Component {
    state = {
        collections: null,
        isFetching: false,
        error: null
    };

    fetchData = () => {
        this.setState({isFetching: true});
        console.log(this.props.match.params);
        fetch(
            `${process.env.REACT_APP_API_SERVER_IP}collection/get_manufacturers_category_collections.php?
                   mname=${this.props.match.params.manufacturer}&category=${this.props.match.params.category}`
            // mname=${decodeURIComponent (this.props.match.params.manufacturer)}&category=${decodeURIComponent (this.props.match.params.category)}`
        )
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    this.setState({collections: null, isFetching: false});
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data =>
                this.setState({
                    collections: data,
                    isFetching: !this.state.isFetching
                })
            )
            .catch(error => this.setState({error, collections: null, isFetching: false}));
    };

    componentDidMount() {
        this.fetchData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.fetchData();
        }
    }

    render() {
        if (this.state.isFetching) return <div className="lds-hourglass"/>;
        if (!this.state.collections)
            return (
                <React.Fragment>
                    <h3 className="my-4 text-center text-lg-center wow fadeInUp animated">
                        No collections were found!
                    </h3>
                    <div className="backButton wow fadeInUp animated">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => window.history.back()}
                        >
                            ←
                        </button>
                    </div>
                </React.Fragment>
            );
        return (
            <React.Fragment>
                <h3 className="my-4 text-center text-lg-center">
                    {this.props.match.params.manufacturer}'s {this.props.match.params.category} Collections
                </h3>
                <div className="backButton wow fadeInUp animated">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ←
                    </button>
                </div>
                <div className="container">
                    <div className="row text-center text-lg-left wow fadeInUp animated">
                        {
                            this.state.collections.map(collection => (
                                    <CollectionThumbnail key={collection.cid} collection={collection}/>
                                )
                            )
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
