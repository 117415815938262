import React, {Component} from 'react'
import Redirect from "react-router-dom/Redirect";

export default class AddProduct extends Component {
    state = {
        loading: true,
        manufacturers: [],
        categories: [],
        collections: [],
        selectedCategory: "",
        selectedManufacturer: "",
        selectedCollection: "",
        exists: false,
        buttonDisabled: false,
        btnStyle: 'btn btn-md btn-success'
    };

    selectDefaultManufacturer() {
        console.log("default passed");
        let element = document.getElementById("manufacturerSelect");
        element.value = "";
        console.log(element.value);
    }

    async componentDidMount() {
        const url1 = `${process.env.REACT_APP_API_SERVER_IP}product/get_all_products.php`;
        const response1 = await fetch(url1);
        const data1 = await response1.json();
        this.setState({products: data1, loading: false});

        const url = `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({categories: data, loading: false})
    }

    handleChange(e) {
        let checkId = e.target.value;
        // checkId = checkId.charAt(0).toLowerCase() + checkId.slice(1);
        // checkId = checkId.replace(/\s/g, '');
        if (e.target.name === "category") {
            console.log(checkId);
            this.setState({selectedCategory: e.target.value });
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    console.log("test:" + data);
                    if (data.length > 0) {
                        this.setState({
                            manufacturers: data,
                            collections: [{"collection": "Select Collection"}],
                        })
                    } else
                        this.setState({
                            selectedManufacturer: ""
                        })
                });
            console.log(this.state.manufacturers);
            this.selectDefaultManufacturer();
        } else if (e.target.name === "mname") {
            console.log("manufacturer");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_manufacturers_category_collections.php?mname=${e.target.value}&category=${this.state.selectedCategory}`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        this.setState({
                            collections: data
                        })
                    } else {
                        this.setState({
                            collections: [],
                            selectedCollection: ""
                        })
                    }
                })
        } else if (e.target.name === "pid") {
            console.log("pid");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}product/get_product_pid.php?pid='${e.target.value}'`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        this.setState({
                            exists: true,
                            buttonDisabled: true,
                            btnStyle: 'btn btn-md btn-secondary'
                        })
                    } else
                        this.setState({
                            exists: false,
                            buttonDisabled: false,
                            btnStyle: 'btn btn-md btn-success'
                        })
                })
        } else {
            console.log("something is wrong EVENT HANDLER");
        }
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Add a new product</p>
                        <form
                            action={process.env.REACT_APP_API_SERVER_IP+"product/create_product.php"}
                            method="POST"
                        >


                            <label /*htmlFor="category"*/ className="grey-text font-weight-light">
                                Category
                            </label>
                            <br/>
                            <select name='category' defaultValue='' onChange={this.handleChange.bind(this)}>
                                <option value="" disabled hidden>Select Category</option>
                                {
                                    this.state.categories.map(m => {
                                        return <option value={m.category} name='category' key={m.category}
                                        >{m.category} </option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>


                            <label className="grey-text font-weight-light">
                                Manufacturer Name
                            </label>
                            <br/>
                            <select id="manufacturerSelect" defaultValue='' name='mname' onChange={this.handleChange.bind(this)}>
                                <option value="" disabled hidden>Select Manufacturer</option>
                                {
                                    this.state.manufacturers.map(m => {
                                        return <option value={m.mname} name='mname' key={m.mname}
                                                       required>{m.mname}</option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>

                            <label htmlFor="collection" className="grey-text font-weight-light">
                                Collection
                            </label>
                            <br/>
                            <select name='collection' defaultValue=''>
                                <option value="" disabled hidden>Select Collection</option>
                                {
                                    this.state.collections.map(m => {
                                        return <option value={m.name} name='name' key={m.name}
                                        >{m.name}</option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>

                            <label className="grey-text font-weight-light">
                                Product ID
                            </label>
                            {this.state.exists ? <span style={{color: 'red', float: 'right'}}>* This product ID already
                  exists!</span> : null}

                            <input
                                type="text"
                                className="form-control"
                                id="pid"
                                name="pid"
                                placeholder="Product ID"
                                onChange={this.handleChange.bind(this)}
                                required
                            />
                            <br/>


                            <label htmlFor="name" className="grey-text font-weight-light">
                                Product Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Product Name"
                                onChange={this.handleChange.bind(this)}
                                required
                            />
                            <br/>
                            <label
                                htmlFor="description"
                                className="grey-text font-weight-light"
                            >
                                Description
                            </label>
                            <input
                                className="form-control"
                                id="description"
                                name="description"
                                placeholder="Description"
                            />
                            <br/>
                            <label
                                htmlFor="technology"
                                className="grey-text font-weight-light"
                            >
                                Technology
                            </label>
                            <input
                                className="form-control"
                                id="technology"
                                name="technology"
                                placeholder="Technology"
                            />
                            <br/>
                            <label
                                htmlFor="thickness"
                                className="grey-text font-weight-light"
                            >
                                Thickness
                            </label>
                            <input
                                className="form-control"
                                id="thickness"
                                name="thickness"
                                placeholder="Thickness"
                            />
                            <br/>
                            <label htmlFor="colour" className="grey-text font-weight-light">
                                Colour
                            </label>
                            <input
                                className="form-control"
                                id="colour"
                                name="colour"
                                placeholder="Colour"
                            />
                            <br/>

                            <label htmlFor="size" className="grey-text font-weight-light">
                                Size
                            </label>
                            <input
                                className="form-control"
                                id="size"
                                name="size"
                                placeholder="Size"
                            />
                            <br/>

                            <label htmlFor="placing" className="grey-text font-weight-light">
                                Placing
                            </label>
                            <input
                                className="form-control"
                                id="placing"
                                name="placing"
                                placeholder="Placing"
                            />
                            <br/>

                            <label htmlFor="specifications" className="grey-text font-weight-light">
                                Specifications
                            </label><br/>
                            <small>Greenguard</small><br/>
                            <small>GreenFrost</small><br/>
                            <small>NSF</small><br/>
                            <small>Frost</small><br/>
                            <small>LEED</small><br/>
                            <small>LEED,Greenguard</small><br/>
                            <small>LEED,Frost,Greenguard</small><br/>
                            <small>LEED,Frost,Greenguard,Stepwise</small><br/>
                            <small>LEED,Puro</small><br/>
                            <small>LEED,Frost,Puro</small><br/>
                            <small>LEED,Frost,Stepwise,Puro</small><br/>
                            <small>Ecolabel,Greenguard</small><br/>
                            <small>Ecolabel,Frost,Greenguard</small><br/>
                            <small>Frost,Greenguard,Stepwise</small><br/>
                            <small>Frost,Greenguard,Stepwise,Bodyplus</small>
                            <textarea
                                className="form-control"
                                id="specifications"
                                name="specifications"
                                placeholder="Specifications"
                            />
                            <br/>

                            <label htmlFor="room" className="grey-text font-weight-light">
                                Room
                            </label>
                            <input
                                className="form-control"
                                id="room"
                                name="room"
                                placeholder="room"
                            />
                            <br/>

                            <label htmlFor="quality" className="grey-text font-weight-light">
                                Quality
                            </label>
                            <input
                                className="form-control"
                                id="quality"
                                name="quality"
                                placeholder="quality"
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image1 URL
                            </label>
                            <input
                                className="form-control"
                                id="img1URL"
                                name="image1"
                                placeholder="imgURL"
                                required
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image2 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image2"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image3 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image3"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image4 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image4"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image5 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image5"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image6 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="imgSource"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image7 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image7"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image8 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image8"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image9 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image9"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image10 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image10"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="technicalDrawing" className="grey-text font-weight-light">
                                Technical Drawing URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="technicalDrawing"
                                placeholder="imgURL"
                            />
                            <br/>

                            <div className="text-center py-4 mt-3">
                                <button type="submit" className={this.state.btnStyle}
                                        disabled={this.state.buttonDisabled}>
                                    Add product to database{' '}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
