import $ from 'jquery';

// this script is used for animated divs
function scroll_to(clicked_link, nav_height) {
    let element_class = clicked_link.attr('href').replace('#', '.');
    let scroll_to = 0;
    if(element_class !== '.top-content') {
        element_class += '-container';
        scroll_to = $(element_class).offset().top - nav_height;
    }
    if($(window).scrollTop() !== scroll_to) {
        $('html, body').stop().animate({scrollTop: scroll_to}, 1000);
    }
}


$(document).ready(function() {
    /*
        Scroll link
    */

    $('a.scroll-link').on('click', function(e) {
        e.preventDefault();
        scroll_to($(this), 0);
    });

    /*
        Background slideshow
    */
    //$('.top-content').backstretch("assets/img/backgrounds/1.jpg");

    /*
        Wow
    */
    // eslint-disable-next-line no-undef
    new WOW().init();
});
