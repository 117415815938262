import React, {Component} from "react";
import Redirect from "react-router-dom/Redirect";

export default class DeleteManufacturer extends Component {
    state = {
        loading: true,
        mname: "",
        manufacturers: [],
        manufacturer: [
            {
                mname: "",
                category: "",
                headquarters: "",
                orderNr: "",
            }
        ],
        manufacturerDefault: [
            {
                mname: "",
                category: "",
                headquarters: "",
                orderNr: "",
            }
        ],
        exists: false,
        buttonDisabled: false,
        btnStyle: "btn btn-md btn-success",
    };


    async componentDidMount() {
        const url =  `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({categories: data, loading: false})

        // const url1 = `http://localhost/api-shullani/manufacturer/get_all_manufacturers.php`;
        // const response1 = await fetch(url1);
        // const data1 = await response1.json();
        // this.setState({manufacturers: data1, loading: false})
    }


    handleChange(e) {
        let checkId = e.target.value;
        if (e.target.name === "category") {
            console.log(checkId);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        console.log("test:" + JSON.stringify(data));
                        this.setState({
                            manufacturer: this.state.manufacturerDefault[0],
                            manufacturers: data,
                            mname: ""
                        })
                    } else
                        this.setState({
                            manufacturer: this.state.manufacturerDefault,
                            mname: ""
                        })
                });
            document.getElementById("manufacturerSelect").value = "";
        } else if (e.target.name === "mname") {
            console.log("manufacturers");
            this.setState({mname: e.target.value}) ;
            console.log("e.target.value: " + e.target.value);

            this.state.manufacturers.forEach(man => {
                if(man.mname===e.target.value)
                {
                    //console.log("man: " + JSON.stringify(man));
                    this.setState({manufacturer: man});
                }
            });

            // this.state.manufacturers.map(man => {
            //     if(man.mname===e.target.value)
            //     {
            //         //console.log("man: " + JSON.stringify(man));
            //         this.setState({manufacturer: man});
            //     }
            // })

        }
        else {
            console.log("something is wrong EVENT HANDLER");
        }
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"/>;
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        const manufacturer = this.state.manufacturer;
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Delete Manufacturer/Category</p>



                        <form
                            action={process.env.REACT_APP_API_SERVER_IP + "manufacturer/delete_manufacturer.php"}
                            method="POST"
                        >


                            <label htmlFor="category" className="grey-text font-weight-light">
                                Category
                            </label>
                            <br/>
                            <select name='category' defaultValue='' onChange={this.handleChange.bind(this)}>
                                <option value='' disabled hidden>Choose Here</option>
                                {
                                    this.state.categories.map(m => {
                                        return <option value={m.category} name='category' key={m.category}
                                        >{m.category} </option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>


                            <label htmlFor="manufacturers" className="grey-text font-weight-light">
                                Manufacturer Name
                            </label>
                            <br/>
                            <select
                                id="manufacturerSelect"
                                name="mname"
                                ref="manufacturers"
                                defaultValue=''
                                onChange={this.handleChange.bind(this)}
                            >
                                <option value='' disabled hidden>Choose here</option>
                                {this.state.manufacturers.map(m => {
                                    return (
                                        <option value={m.mname} name="mname" key={m.mname} required>
                                            {m.mname}
                                        </option>
                                    );
                                })}
                            </select>
                            <br/>
                            <br/>



                            <label htmlFor="headquarters" className="grey-text font-weight-light">
                                Headquarters
                            </label>
                            <input
                                className="form-control"
                                id="headquarters"
                                name="headquarters"
                                value={manufacturer.headquarters}
                                readOnly
                            />
                            <br/>


                            <label htmlFor="orderNr" className="grey-text font-weight-light">
                                OrderNr
                            </label>
                            <input
                                className="form-control"
                                id="orderNr"
                                name="orderNr"
                                value={manufacturer.orderNr}
                                readOnly
                            />
                            <br/>


                            <div className="text-center py-4 mt-3">
                                <button
                                    type="submit"
                                    className={this.state.btnStyle}
                                    disabled={this.state.buttonDisabled}
                                >
                                    Delete category{" "}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}