import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Admin from "./Admin";
import AddAdmin from "./AddAdmin";
import RemoveAdmin from "./RemoveAdmin";

import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";

import AddCollection from "./AddCollection";
import EditCollection from "./EditCollection";
import DeleteCollection from "./DeleteCollection";

import AddCategory from "./AddCategory";
import DeleteManufacturer from "./DeleteManufacturer";

import AddProject from "./AddProject";
import EditProject from "./EditProject";
import DeleteProject from "./DeleteProject";

class AdminRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/admin" component={Admin}/>
                <Route path="/admin/add-administrator" component={AddAdmin}/>
                <Route path="/admin/remove-administrator" component={RemoveAdmin}/>
                <Route path="/admin/add-product" component={AddProduct}/>
                <Route path="/admin/add-project" component={AddProject}/>
                <Route path="/admin/add-collection" component={AddCollection}/>
                <Route path="/admin/add-category" component={AddCategory}/>
                <Route path="/admin/edit-product" component={EditProduct}/>
                <Route path="/admin/edit-collection" component={EditCollection}/>
                <Route path="/admin/edit-project" component={EditProject}/>
                <Route path="/admin/delete-product" component={DeleteProduct}/>
                <Route path="/admin/delete-collection" component={DeleteCollection}/>
                <Route path="/admin/delete-manufacturer" component={DeleteManufacturer}/>
                <Route path="/admin/delete-project" component={DeleteProject}/>
            </Switch>
        );
    }
}

export default AdminRoutes;