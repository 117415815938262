import React, {Component} from "react";
import ProductThumbnail from "./ProductThumbnail";
// import ImageGallery from "react-image-gallery";
// import noImage from "../../../images/no-image.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../../mdb-master/js/script.js";
import "../../../scripts.js";

export default class ColorProducts extends Component {
    state = {
        products: null,
        isFetching: false,
        hasImages: false,
        images: [],
        error: null
    };

    fetchData = () => {
        this.setState({isFetching: true});
        console.log(this.props.match.params);

        // get collection products
        fetch(
            `${process.env.REACT_APP_API_SERVER_IP}product/get_collection_colour_products.php?cid=${this.props.match.params.cid}&colour=${this.props.match.params.colour}`
        )
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    this.setState({products: null, isFetching: false});
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data =>
                this.setState({
                    products: data,
                    collectionName: data[0].collection,
                    isFetching: !this.state.isFetching
                })
            )
            .catch(error => this.setState({error, products: null, isFetching: false}));
    };

    componentDidMount() {
        this.fetchData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.fetchData();
        }
    }

    render() {
        if (this.state.isFetching) return <div className="lds-hourglass"/>;
        if (!this.state.products) {
            return (
                <React.Fragment>
                    <h3 className="my-4 text-center text-lg-center wow fadeInUp animated">
                        No products were found!
                    </h3>
                    <div className="backButton wow fadeInUp animated">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => window.history.back()}
                        >
                            ←
                        </button>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <h2 className="my-4 text-center text-lg-center wow fadeIn animated">
                    {this.state.collectionName}  {this.props.match.params.colour} Products
                </h2>

                <div className="container">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            <div className="backButton wow fadeInUp animated">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => window.history.back()}
                                >
                                    ←
                                </button>
                            </div>
                            <div className="row text-center text-lg-left wow fadeInUp animated">
                                {
                                    this.state.products.map(product => (
                                            <ProductThumbnail
                                                category="Tiles"
                                                mname={this.props.match.params.manufacturer}
                                                cid={this.props.match.params.cid}
                                                color={this.props.match.params.colour}
                                                key={product.id}
                                                product={product}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
