import React, {Component} from "react";
import Redirect from "react-router-dom/Redirect";

export default class EditProject extends Component {
    state = {
        loading: true,
        projects: [],
        project: [
            {
                id: "",
                projectName: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                image11: "",
                image12: "",
                image13: "",
                image14: "",
                image15: "",
            }
        ],
        projectDefault: [
            {
                id: "",
                projectName: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                image11: "",
                image12: "",
                image13: "",
                image14: "",
                image15: "",
            }
        ],
        exists: false,
        buttonDisabled: false,
        btnStyle: "btn btn-md btn-success",
    };


    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP}project/get_all_projects.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({projects: data, loading: false})
    }

    handleInputChange(e) {
        this.setState({project: [{...this.state.project[0], [e.target.name]: e.target.value}]});
        console.log(this.state.project);
    }

    handleChange(e){
        let value = e.target.value;
        this.state.projects.forEach(e => {
            if(e.projectName===value){
                console.log(e);
                this.setState({project: [e]});
            }
        });
        // this.setState({project: [{...this.state.projects[0], [e.target.name]: e.target.value}]});
        console.log(value);
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"/>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        // const mname = this.state.mname;
        const project = this.state.project[0];
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Edit project</p>


                        <label htmlFor="projectName" className="grey-text font-weight-light">
                            Project Name
                        </label>
                        <br/>
                        <select id="projectName" name='projectName' onChange={this.handleChange.bind(this)} defaultValue=''>
                            <option value="" disabled hidden>Choose here</option>
                            {
                                this.state.projects.map(m => {
                                    return <option value={m.projectName} name='projectName' key={m.projectName}
                                    >{m.projectName}</option>
                                })
                            }
                        </select>
                        <br/>
                        <br/>


                        <form
                            action={process.env.REACT_APP_API_SERVER_IP+"project/edit_project.php"}
                            method="POST"
                        >

                            <input type="hidden" name="id" value={project.id}/>

                            <label htmlFor="projectName" className="grey-text font-weight-light">
                                Project Name
                            </label>
                            <br/>
                            <input
                                className="form-control"
                                id="projectNameEdit"
                                name="projectName"
                                value={project.projectName}
                                onChange={this.handleInputChange.bind(this)}
                                required
                            />
                            <br/>
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image1 URL
                            </label>
                            <input
                                className="form-control"
                                id="img1URL"
                                name="image1"
                                value={project.image1}
                                onChange={this.handleInputChange.bind(this)}
                                required
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image2 URL
                            </label>
                            <input
                                className="form-control"
                                id="img2URL"
                                name="image2"
                                value={project.image2}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image3 URL
                            </label>
                            <input
                                className="form-control"
                                id="img3URL"
                                name="image3"
                                value={project.image3}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image4 URL
                            </label>
                            <input
                                className="form-control"
                                id="img4URL"
                                name="image4"
                                value={project.image4}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image5 URL
                            </label>
                            <input
                                className="form-control"
                                id="img5URL"
                                name="image5"
                                value={project.image5}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image6 URL
                            </label>
                            <input
                                className="form-control"
                                id="img6URL"
                                name="imgSource"
                                value={project.image6}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image7 URL
                            </label>
                            <input
                                className="form-control"
                                id="img7URL"
                                name="image7"
                                value={project.image7}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image8 URL
                            </label>
                            <input
                                className="form-control"
                                id="img8URL"
                                name="image8"
                                value={project.image8}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image9 URL
                            </label>
                            <input
                                className="form-control"
                                id="img9URL"
                                name="image9"
                                value={project.image9}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image10 URL
                            </label>
                            <input
                                className="form-control"
                                id="img10URL"
                                name="image10"
                                value={project.image10}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image11 URL
                            </label>
                            <input
                                className="form-control"
                                id="img11URL"
                                name="image11"
                                value={project.image11}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image12 URL
                            </label>
                            <input
                                className="form-control"
                                id="img12URL"
                                name="image12"
                                value={project.image12}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image13 URL
                            </label>
                            <input
                                className="form-control"
                                id="img13URL"
                                name="image10"
                                value={project.image13}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image14 URL
                            </label>
                            <input
                                className="form-control"
                                id="img14URL"
                                name="image14"
                                value={project.image14}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image15 URL
                            </label>
                            <input
                                className="form-control"
                                id="img15URL"
                                name="image15"
                                value={project.image15}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <br/>

                            <br/>
                            <br/>
                            <div className="text-center py-4 mt-3">
                                <button
                                    type="submit"
                                    className={this.state.btnStyle}
                                    disabled={this.state.buttonDisabled}
                                >
                                    Edit project{" "}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}