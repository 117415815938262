import React, {Component} from 'react'
import Redirect from "react-router-dom/Redirect";

export default class AddProject extends Component {
    state = {
        loading: true,
        projects: [],
        project: [
            {
                id: "",
                projectName: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                image11: "",
                image12: "",
                image13: "",
                image14: "",
                image15: "",
            }
        ],
        projectDefault: [
            {
                id: "",
                projectName: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
                image6: "",
                image7: "",
                image8: "",
                image9: "",
                image10: "",
                image11: "",
                image12: "",
                image13: "",
                image14: "",
                image15: "",
            }
        ],
        exists: false,
        buttonDisabled: false,
        btnStyle: "btn btn-md btn-success",
    };

    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP}project/get_all_projects.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({projects: data, loading: false});
    }

    handleChange(e){
        this.setState({exists: false});
        this.state.projects.forEach(project => {
            if(project.projectName===e.target.value) {
                this.setState({exists: true});
                console.log("True");
            }
        })
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Add a new project</p>
                        <form
                            action={process.env.REACT_APP_API_SERVER_IP+"project/create_project.php"}
                            method="POST"
                        >

                            <label htmlFor="name" className="grey-text font-weight-light">
                                Project Name
                            </label>
                            {this.state.exists ? <span style={{color: 'red', float: 'right'}}>* This project name already
                  exists!</span> : null}
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="projectName"
                                placeholder="Project Name"
                                onChange={this.handleChange.bind(this)}
                                required
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image1 URL
                            </label>
                            <input
                                className="form-control"
                                id="img1URL"
                                name="image1"
                                placeholder="imgURL"
                                required
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image2 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image2"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image3 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image3"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image4 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image4"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image5 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image5"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image6 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="imgSource"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image7 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image7"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image8 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image8"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image9 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image9"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image10 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image10"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image11 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image11"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image12 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image12"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image13 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image13"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image14 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image14"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image14 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image14"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image15 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image15"
                                placeholder="imgURL"
                            />
                            <br/>

                            <div className="text-center py-4 mt-3">
                                <button type="submit" className={this.state.btnStyle}
                                        disabled={this.state.exists}>
                                    Add project to database{' '}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
