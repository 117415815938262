import React, {Component} from 'react'
import Redirect from "react-router-dom/Redirect";

export default class AddCategory extends Component {
    state = {
        loading: true,
        manufacturers: [],
        categories: [],
        exists: false,
        buttonDisabled: false,
        btnStyle: 'btn btn-md btn-success',
        manufacturer: '',
        category: '',
    };

    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP + "manufacturer/get_manufacturers_and_categories.php"}`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({manufacturers: data, loading: false});
        console.log(this.state);
    }

    handleChange(e) {
        let checkId = e.target.value;
        if (e.target.name === "category") {
            console.log(checkId);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    console.log("test:" +  JSON.stringify(data));
                    if (data.length > 0) {
                        this.setState({
                            manufacturers: data,
                            category: checkId,
                        })
                    } else
                        this.setState({
                            category: ""
                        })
                });
        } else if (e.target.name === "mname") {
            console.log("manufacturer");
            this.setState({manufacturer: checkId});
            this.state.manufacturers.forEach(man => {
                console.log({man} + " " + this.state.manufacturer + ", " + man.category +" " + checkId);
                if(man.mname===checkId && man.category===this.state.category) {
                    console.log("nice");
                    this.setState({exists: true});
                }
                else{
                    this.setState({exists: false});
                }
            })
        }
        else {
            console.log("something is wrong EVENT HANDLER");
        }
    }



    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>;
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Add a new category</p>
                        <form
                            action={process.env.REACT_APP_API_SERVER_IP + "/manufacturer/create_manufacturer.php"}
                            method="POST"
                        >

                            <br/>
                            <label htmlFor="category" className="grey-text font-weight-light">
                                Category
                            </label>
                            {this.state.exists ? <span style={{color: 'red', float: 'right'}}>* This category and manufacturer already
                  exists!</span> : null}
                            <input
                                className="form-control"
                                id="category"
                                name="category"
                                placeholder="Category"
                                required
                                onChange={this.handleChange.bind(this)}
                            />
                            <br/>


                            <label htmlFor="mname" className="grey-text font-weight-light">
                                Manufacturer Name
                            </label>
                            <input
                                className="form-control"
                                id="mname"
                                name="mname"
                                placeholder="Manufacturer"
                                required
                                onChange={this.handleChange.bind(this)}
                            />
                            <br/>



                            <label htmlFor="headquarters" className="grey-text font-weight-light">
                                Headquarters
                            </label>
                            <input
                                className="form-control"
                                id="headquarters"
                                name="headquarters"
                                placeholder="Headquarters"
                                required
                            />
                            <br/>

                            <label htmlFor="orderNr" className="grey-text font-weight-light">
                                Order Nr
                            </label>
                            <input
                                className="form-control"
                                id="orderNr"
                                name="orderNr"
                                placeholder="OrderNr"
                                required
                            />
                            <br/>


                            <br/>
                            <div className="text-center py-4 mt-3">
                                <button type="submit" className={this.state.btnStyle}
                                        disabled={this.state.exists}>
                                    Add manufacturer/category to database{' '}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
