import { Link } from 'react-router-dom'

export default function Header(){
    return (
      <nav className="navbar navbar-expand-lg navbar-dark">
        <Link to="/" className="navbar-brand">
          <img src={require('../../logo-white-resize.png').default} height={"50px"} alt="Logo"/>
        </Link>
        <button
          id="navToggler"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"/>
        </button>


        <div className="collapse navbar-collapse justify-content-end"
             id="navbarSupportedContent">
            <ul className="navbar-nav justify-content-center" >
                <div id="google_translate_element"/>
            <li className="nav-item" data-toggle="collapse"
                data-target=".navbar-collapse">
              <Link to="/" className="nav-link notranslate">
                Home
              </Link>
            </li>
            <li className="nav-item" data-toggle="collapse"
                data-target=".navbar-collapse">
              <Link to="/product" className="nav-link">
                Products
              </Link>
            </li>
            <li className="nav-item" data-toggle="collapse"
                data-target=".navbar-collapse">
              <Link to="/projects" className="nav-link">
                Our Projects
              </Link>
            </li>
            <li className="nav-item" data-toggle="collapse"
                data-target=".navbar-collapse">
              <Link to="/about" className="nav-link">
                About Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
}
