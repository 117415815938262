import React, { Component } from 'react'
import ProductMenu from './ProductMenu'
import LatestCollections from '../LatestCollections'
import { Switch, Route } from 'react-router-dom'
import ProductSpecs from "./ProductSpecs";
// import ProductSpecsModal from "./ProductSpecsModal";
import ManufacturerCollections from './ManufacturerCollections'
import CollectionProducts from './CollectionProducts'
import CollectionColors from "./CollectionColors";
import ColorProducts from "./ColorProducts";
import ManufacturerProducts from "./ManufacturerProducts";
import NotFound from "../NotFound";

class ProductRoutes extends Component {
  state = {
    loading: true,
    manufacturers: []
  };

  async componentDidMount () {
    // check if there isn't any manufacturer then dont render
    const url = `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_manufacturers.php`;
    const response = await fetch(url);
    const data = await response.json();
    this.setState({ manufacturers: data, loading: false });
  }

  render () {
    if (this.state.loading)
      return <div className="lds-hourglass"/>;
    return (
      <section className="py-5">
        <div className="container">
          <ProductMenu/>
          <div className="row text-center text-lg-left"/>
          <Switch>
            <Route exact path="/product" component={LatestCollections}/>
            <Route exact path="/product/Bath Tubs - Jacuzzi/Kolpasan" render={(props) => (<ManufacturerProducts {...props} category="Bath Tubs - Jacuzzi" manufacturer="Kolpasan" />)}/>
            <Route exact path="/product/Shower Tubs/Kolpasan" render={(props) => (<ManufacturerProducts {...props} category="Shower Tubs" manufacturer="Kolpasan" />)}/>
            <Route exact path="/product/:category/:manufacturer"  component={ManufacturerCollections}/>
            <Route exact path="/product/Tiles/:manufacturer/:cid" component={CollectionColors} />
            <Route exact path="/product/Tiles/:manufacturer/:cid/:colour" component={ColorProducts}/>
            <Route exact path="/product/Tiles/:manufacturer/:cid/:colour/:id" component={ProductSpecs}/>
            <Route exact path="/product/:category/:manufacturer/:cid" component={CollectionProducts}/>
            <Route exact path="/product/:category/:manufacturer/:cid/:id" component={ProductSpecs}/>
            <Route component={NotFound}/>
          </Switch>
        </div>
      </section>
    )
  }
}

export default ProductRoutes
