import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class CollectionThumbnail extends Component {
    render() {
        let {cid, name, image1, category, mname, quality} = this.props.collection;
        let link = `/product/${category}/${mname}/${cid}`;
        quality = quality || '';

        return (
            <div className="col-md-4 notranslate">
                <div className="card mb-4 shadow-sm">
                    <Link to={link}>
                        <img className="img-fluid img-thumbnail"
                             alt={name}
                             src={"https://lh3.googleusercontent.com/d/" + image1}
                             title={name + ' ' + quality}
                        />
                        <div className="card-body">
                            <p className="card-text">{name}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group"/>
                                <small className="font-weight-bold blue-text">{quality.toUpperCase()}</small>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}
