import React, {} from 'react'
import './App.css'
import './animate.css'
import './animatedBootstrap.css'
import './blog.css'

import './mdb-master/css/mdb-pro.min.css'
// import './mdb-master/css/style.css'

import Home from './components/pages/homepage/Home'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import NotFound from './components/pages/NotFound'
import Header from './components/header/Header'
import ProductRoutes from './components/pages/products/ProductRoutes'
import About from './components/pages/about/About'
import Footer from './components/footer/Footer'
import Login from './components/pages/login/Login'
import Projects from './components/pages/projects/Projects'
import AdminRoutes from "./components/admin/AdminRoutes";

function App(){
    return (
      <Router>
        <React.Fragment>
          <Header/>
          <section id="mainContent">
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/product" component={ProductRoutes}/>
              <Route path="/about" component={About}/>
              <Route path="/projects" component={Projects}/>
              <Route path="/admin" component={AdminRoutes}/>
              <Route path="/login" component={Login}/>
              <Route component={NotFound}/>
            </Switch>
          </section>
          <Footer/>
        </React.Fragment>
      </Router>
    )
}

export default App;
