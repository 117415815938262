import React, {Component} from "react";
// import CollectionThumbnail from "../products/CollectionThumbnail";
import ImageGallery from "react-image-gallery";
import noImage from "../../../images/no-image.png";

export default class Projects extends Component {
    state = {
        loading: true,
        projects: [],
        images: [],
        hasImages: true,
    };

    async componentDidMount() {
        const url = process.env.REACT_APP_API_SERVER_IP + "project/get_all_projects.php";
        // const url = "http://localhost/api-shullani/collection/get_latest_collections.php";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({projects: data, loading: false});
        console.log(this.state);


        // data.forEach((item) => {
        //     console.log(item);
        //     for (const [key, value] of Object.entries(item)) {
        //         // console.log(`${key}: ${value}`);
        //
        //             if (value) {
        //                 console.log(key);
        //                 console.log(value);
        //
        //                 delete
        //
        //                 this.state.images.push({
        //                     original: String('https://drive.google.com/uc?export=view&id=' + value),
        //                     thumbnail: String('https://drive.google.com/uc?export=view&id=' + value),
        //                 });
        //                 this.setState({hasImages: true});
        //             }
        //     }
        // });

        // let container = this.document.getElementById('container').style;
    }

    // showGallery() {
    //     this.state.projects.map((project) => {
    //         console.log(project);
    //         const images = [];
    //         for (const [key, value] of Object.entries(project)) {
    //             if (key.includes("image")) {
    //                 if (value) {
    //                     images.push({
    //                         original: String('https://drive.google.com/uc?export=view&id=' + value),
    //                         thumbnail: String('https://drive.google.com/uc?export=view&id=' + value),
    //                     });
    //                 }
    //             }
    //         }
    //         console.log(images);
    //         return (
    //             <ImageGallery items={images} onErrorImageURL={noImage}>
    //             </ImageGallery>
    //         );
    //     })
    // }

    fun(){

    }


    render() {
        if (this.state.loading) {
            return <div className="lds-hourglass"/>
        } else {
            return (
                <React.Fragment>
                    {/*<h3 className="my-4 text-center text-lg-center">Our Projects</h3>*/}
                    <div className="container notranslate">
                        <div className="album py-5 bg-light wow delay-1s fadeInRight animated">
                            <div>
                                {
                                    this.state.projects.map((project) => {
                                        console.log(project);
                                        const images=[];
                                        for (const [key, value] of Object.entries(project)) {
                                            if(key.includes("image")) {
                                                if (value) {
                                                    images.push({
                                                        original: String('https://lh3.googleusercontent.com/d/' + value),
                                                        thumbnail: String('https://lh3.googleusercontent.com/d/' + value),
                                                    });
                                                }
                                            }
                                        }
                                        console.log(images);
                                        return (
                                            <div className="col-sm">
                                                <h3 className="my-4 text-center text-lg-center">{project.projectName}</h3>
                                                <ImageGallery items={images} onErrorImageURL={noImage}/>
                                                {/*<br/>*/}
                                            </div>
                                        );
                                    })
                                }

                                {/*<div>*/}
                                {/*    {this.state.hasImages ? (*/}
                                {/*        <ImageGallery items={this.state.images} onErrorImageURL={noImage}/>*/}
                                {/*    ) : (*/}
                                {/*        <br/>*/}
                                {/*    )*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
