import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class ProductColorThumbnail extends Component {
    render() {
        const image = this.props.image;
        const category = this.props.category;
        const mname = this.props.mname;
        const cid = this.props.cid;

        const answer_array = image.split(',');
        const imageLink = answer_array[0];
        const color = answer_array[1];
        console.log(answer_array);

        const link = `/product/${category}/${mname}/${cid}/${color}`;

        return (
            <div className="col-md-4">
                <div className="card mb-4 shadow-sm">
                    <Link to={link}>
                        <img className="img-fluid img-thumbnail"
                             alt={color}
                             src={"https://lh3.googleusercontent.com/d/" + imageLink}
                             title="Click for the larger version."
                        />
                        <div className="card-body">
                            <p className="card-text">{color}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group"/>
                                {/*<small className="text-muted">{size}</small>*/}
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}