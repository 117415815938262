import React, {Component} from 'react';
import Redirect from "react-router-dom/Redirect";

export default class RemoveAdmin extends Component {
    constructor(props){
        super(props);

        // Binding this keyword
        this.handleClick = this.handleClick.bind(this)
    }

    state = {
        loading: true,
        users: [
            {
                name: "",
                username: "",
            }
        ],
        buttonDisabled: true,
        btnStyle: "btn btn-md btn-success",
    };
    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP + "/administrator/get_all_administrators.php"}`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({ users: data, loading: false });
        console.log(this.state.users);
    }

    handleClick(){
        // Changing state
        this.setState({buttonDisabled: false});
    }

    render()
    {
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        if (this.state.loading) {
            return <div className="lds-hourglass"></div>;
        } else {
            return (
                <div className="container">
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => window.history.back()}
                        >
                            Back
                        </button>
                    </div>
                    <div className="card mx-xl-5">
                        <div className="card-body">
                            <p className="h4 text-center py-4">Delete Admin</p>
                            <form
                                action={process.env.REACT_APP_API_SERVER_IP + "administrator/remove_administrator.php"}
                                method="POST"
                            >
                                <label htmlFor="user" className="grey-text font-weight-light">
                                    Admin
                                </label>
                                <br/>
                                <select name="username" ref="user" defaultValue='' onClick={this.handleClick}>
                                    <option value="" disabled hidden>
                                        Choose here
                                    </option>
                                    {this.state.users.map(m => {
                                        return (
                                            <option value={m.username} name="username" key={m.username} required>
                                                {m.name}, {m.username}
                                            </option>
                                        );
                                    })}
                                </select>
                                <br/>
                                <br/>
                                <button
                                    type="submit"
                                    className={this.state.btnStyle}
                                    disabled={this.state.buttonDisabled}
                                >
                                    Delete Administrator{" "}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
    }
}