import React, {Component} from "react";
import CollectionThumbnail from "./products/CollectionThumbnail";
import ReactPaginate from 'react-paginate';


export default class LatestCollections extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            latestCollections: [],
            currentPage: 0
        };
    }


    async componentDidMount() {
        // console.log(process.env);
        const url = `${process.env.REACT_APP_API_SERVER_IP}collection/get_latest_collections.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({latestCollections: data, loading: false});
        console.log(this.state);
    }

    handlePageClick({ selected: selectedPage }) {
        this.setState({currentPage: selectedPage});
        document.getElementById("title").scrollIntoView();
    }

    render() {
        let data  = this.state.latestCollections;
        const PER_PAGE = 9;
        const offset = this.state.currentPage * PER_PAGE;
        const currentPageData = data
            .slice(offset, offset + PER_PAGE).map(latestCollection => (
            <CollectionThumbnail key={latestCollection.cid} collection={latestCollection}/>
        ));
        const pageCount = Math.ceil(data.length / PER_PAGE);

        if (this.state.loading) {
            return <div className="lds-hourglass"></div>
        } else {
            return (
                <React.Fragment>
                    <h3 id="title" className="my-4 text-center text-lg-center">Latest Collections</h3>
                    <div className="container">
                        <div className="album py-3 bg-grey-light wow fadeInUp animated">
                            <div className="container">
                                {/*<div className="row">*/}
                                {/*    {this.state.latestCollections.map(latestCollection => (*/}
                                {/*        <CollectionThumbnail key={latestCollection.cid} collection={latestCollection}/>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                                <div className="row">{currentPageData}</div>
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={this.handlePageClick.bind(this)}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    containerClassName={'d-flex align-items-center justify-content-center pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
