import React, {Component} from "react";
import Redirect from "react-router-dom/Redirect";

export default class EditCollection extends Component {
    state = {
        loading: true,
        manufacturers: [],
        collections: [],
        mname: "",
        collection: [
            {
                cid: "",
                name: "",
                mname: "",
                description: "",
                subDescription: "",
                category: "",
                colours: "",
                surfaces: "",
                sizes: "",
                characteristics: "",
                certifications: "",
                structures: "",
                effects: "",
                type: "",
                room: "",
                dateCreated: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
            }
        ],
        collectionDefault: [
            {
                cid: "",
                name: "",
                mname: "",
                description: "",
                subDescription: "",
                category: "",
                colours: "",
                surfaces: "",
                sizes: "",
                characteristics: "",
                certifications: "",
                structures: "",
                effects: "",
                type: "",
                room: "",
                dateCreated: "",
                image1: "",
                image2: "",
                image3: "",
                image4: "",
                image5: "",
            }
        ],
        exists: false,
        buttonDisabled: false,
        btnStyle: "btn btn-md btn-success",
    };

    selectDefault() {
        console.log("default passed");
        let manufacturerSelect = document.getElementById("manufacturerSelect");
        manufacturerSelect.value = "";
        let collectionSelect = document.getElementById("name");
        collectionSelect.value = "";
        console.log(collectionSelect.value);
    }

    async componentDidMount() {
        const url2 =`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response2 = await fetch(url2);
        const data2 = await response2.json();
        this.setState({categories: data2, loading: false})
    }

    getcollection() {
        if (this.refs.collection.value === "") return;
        this.setState({
            collection: this.state.collections.filter(
                collection => collection.id === this.refs.collection.value
            )
        });
        console.log(this.state.collection[0]);
    }

    updateManufacturer() {
        if (this.refs.manufacturer.value === "") return;
        this.setState({mname: this.refs.manufacturer.value});
        this.setState({collection: this.state.collectionDefault});
        //this.refs.collection.value = "";
        console.log(this.state.collection[0]);
    }

    handleInputChange(e) {
        this.setState({collection: [{...this.state.collection[0], [e.target.name]: e.target.value}]});
        console.log(this.state.collection);
    }

    handleChange(e) {
        let checkId = e.target.value;
        if (e.target.name === "category") {
            this.setState({selectedCategory: e.target.value });
            console.log(checkId);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    console.log("test:" + data);
                    if (data.length > 0) {
                        this.setState({
                            manufacturers: data,
                            collections: [],
                            collection: this.state.collectionDefault
                        })
                    } else
                        this.setState({
                            selectedManufacturer: ""
                        })
                });
            console.log(this.state.manufacturers);
            this.selectDefault();
        } else if (e.target.name === "manufacturer") {
            console.log("manufacturer");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_manufacturers_category_collections.php?mname=${e.target.value}&category=${this.state.selectedCategory}`)
                .then(response => response.json())
                .then(data => {
                    console.log("collections: " + data);
                    if (data.length > 0) {
                        this.setState({
                            collections: data,
                        })
                    } else {
                        this.setState({
                            collection: this.state.collectionDefault,
                            collections: [],
                            selectedCollection: ""
                        })
                    }
                })
        } else if (e.target.name === "name") {
            console.log("collectionName");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_collection.php?cid=${e.target.value}`)
                .then(response => response.json())
                .then(data => {
                    console.log("collection: " + data);
                    if (data.length > 0) {
                        this.setState({
                            collection: data
                        })
                    } else {
                        this.setState({
                            collection: this.state.collectionDefault,
                        })
                    }
                });
        } else {
            console.log("something is wrong EVENT HANDLER");
        }
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        const collection = this.state.collection[0];
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Delete collection</p>


                        <label htmlFor="category" className="grey-text font-weight-light">
                            Category
                        </label>
                        <br/>
                        <select name='category' defaultValue="" onChange={this.handleChange.bind(this)}>
                            <option value="" disabled hidden>Choose Here</option>
                            {
                                this.state.categories.map(m => {
                                    return <option value={m.category} name='category' key={m.category}
                                    >{m.category} </option>
                                })
                            }
                        </select>
                        <br/>
                        <br/>


                        <label htmlFor="manufacturer" className="grey-text font-weight-light">
                            Manufacturer Name
                        </label>
                        <br/>
                        <select
                            id="manufacturerSelect"
                            name="manufacturer"
                            ref="manufacturer"
                            onChange={this.handleChange.bind(this)}
                            defaultValue=""
                        >
                            <option value="" disabled hidden>Choose here</option>
                            {this.state.manufacturers.map(m => {
                                return (
                                    <option value={m.mname} name="mname" key={m.mname} required>
                                        {m.mname}
                                    </option>
                                );
                            })}
                        </select>
                        <br/>
                        <br/>


                        <label htmlFor="collection" className="grey-text font-weight-light">
                            Collection
                        </label>
                        <br/>
                        <select id="name" name='name' defaultValue="" onChange={this.handleChange.bind(this)}>
                            <option value="" disabled hidden>Choose here</option>
                            {
                                this.state.collections.map(m => {
                                    return <option value={m.cid} name='name' key={m.name}
                                    >{m.name}</option>
                                })
                            }
                        </select>
                        <br/>
                        <br/>


                        <label htmlFor="name" className="grey-text font-weight-light">
                            Name
                        </label>
                        <input
                            className="form-control"
                            id="name"
                            name="name"
                            value={collection.name}
                            //placeholder="Name"
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label htmlFor="description" className="grey-text font-weight-light">
                            Description
                        </label>
                        <input
                            className="form-control"
                            id="description"
                            name="description"
                            value={collection.description}
                            //placeholder="Description"
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label htmlFor="subDescription" className="grey-text font-weight-light">
                            SubDescription
                        </label>
                        <input
                            className="form-control"
                            id="subDescription"
                            name="subDescription"
                            value={collection.subDescription}
                            //placeholder="Description"
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label htmlFor="colours" className="grey-text font-weight-light">
                            Colours
                        </label>
                        <input
                            className="form-control"
                            id="colours"
                            name="colours"
                            value={collection.colours}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Colour"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="surfaces" className="grey-text font-weight-light">
                            Surfaces
                        </label>
                        <input
                            className="form-control"
                            id="surfaces"
                            name="surfaces"
                            value={collection.surfaces}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="sizes" className="grey-text font-weight-light">
                            Sizes
                        </label>
                        <input
                            className="form-control"
                            id="sizes"
                            name="sizes"
                            value={collection.sizes}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="size"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="characteristics" className="grey-text font-weight-light">
                            Characteristics
                        </label>
                        <input
                            className="form-control"
                            id="characteristics"
                            name="characteristics"
                            value={collection.characteristics}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="certifiations" className="grey-text font-weight-light">
                            Certifications
                        </label>
                        <input
                            className="form-control"
                            id="certifications"
                            name="certifications"
                            value={collection.certifications}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="structures" className="grey-text font-weight-light">
                            Structures
                        </label>
                        <input
                            className="form-control"
                            id="structures"
                            name="structures"
                            value={collection.structures}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="effects" className="grey-text font-weight-light">
                            Effects
                        </label>
                        <input
                            className="form-control"
                            id="effects"
                            name="effects"
                            value={collection.effects}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="type" className="grey-text font-weight-light">
                            Type
                        </label>
                        <input
                            className="form-control"
                            id="type"
                            name="type"
                            value={collection.type}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="Thickness"
                            readOnly
                        />
                        <br/>


                        <label htmlFor="room" className="grey-text font-weight-light">
                            Room
                        </label>
                        <input
                            className="form-control"
                            id="room"
                            name="room"
                            value={collection.room}
                            onChange={this.handleInputChange.bind(this)}
                            //placeholder="room"
                            readOnly
                        />
                        <br/>

                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image1 URL
                        </label>
                        <input
                            className="form-control"
                            id="img1URL"
                            name="image1"
                            value={collection.image1}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>

                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image2 URL
                        </label>
                        <input
                            className="form-control"
                            id="img2URL"
                            name="image2"
                            value={collection.image2}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image3 URL
                        </label>
                        <input
                            className="form-control"
                            id="img3URL"
                            name="image3"
                            value={collection.image3}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image4 URL
                        </label>
                        <input
                            className="form-control"
                            id="img4URL"
                            name="image4"
                            value={collection.image4}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image5 URL
                        </label>
                        <input
                            className="form-control"
                            id="img5URL"
                            name="image5"
                            value={collection.image5}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image6 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image6"
                            value={collection.image6}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image7 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image7"
                            value={collection.image7}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image8 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image8"
                            value={collection.image8}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image9 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image9"
                            value={collection.image9}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image10 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image10"
                            value={collection.image10}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image11 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image11"
                            value={collection.image11}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image12 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image12"
                            value={collection.image12}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image13 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image13"
                            value={collection.image13}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image14 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image14"
                            value={collection.image14}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Image15 URL
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="image15"
                            value={collection.image15}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image1 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg1URL"
                            name="colorImage1"
                            value={collection.colorImage1}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image2 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg2URL"
                            name="colorImage2"
                            value={collection.colorImage2}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image3 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg3URL"
                            name="colorImage3"
                            value={collection.colorImage3}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image4 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg4URL"
                            name="colorImage4"
                            value={collection.colorImage4}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image5 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg5URL"
                            name="colorImage5"
                            value={collection.colorImage5}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image6 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg6URL"
                            name="colorImage6"
                            value={collection.colorImage6}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image7 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg7URL"
                            name="colorImage7"
                            value={collection.colorImage7}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image8 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="colorImg8URL"
                            name="colorImage8"
                            value={collection.colorImage8}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image9 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage9"
                            value={collection.colorImage9}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image10 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage10"
                            value={collection.colorImage10}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image11 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage11"
                            value={collection.colorImage11}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image12 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage12"
                            value={collection.colorImage12}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image13 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage13"
                            value={collection.colorImage13}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image14 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage14"
                            value={collection.colorImage14}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image15 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage15"
                            value={collection.colorImage15}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image16 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage16"
                            value={collection.colorImage16}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image17 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage17"
                            value={collection.colorImage17}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image18 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage18"
                            value={collection.colorImage18}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image19 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage19"
                            value={collection.colorImage19}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image20 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage20"
                            value={collection.colorImage20}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image21 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage21"
                            value={collection.colorImage21}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image22 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage22"
                            value={collection.colorImage22}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image23 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage23"
                            value={collection.colorImage23}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image24 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage24"
                            value={collection.colorImage24}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>
                        <label htmlFor="imgURL" className="grey-text font-weight-light">
                            Tiles - Image25 URL,color
                        </label>
                        <input
                            className="form-control"
                            id="imgURL"
                            name="colorImage25"
                            value={collection.colorImage25}
                            onChange={this.handleInputChange.bind(this)}
                            readOnly
                        />
                        <br/>


                        <form
                            action={process.env.REACT_APP_API_SERVER_IP+"collection/delete_collection.php"}
                            method="POST"
                        >

                            <input type="hidden" name="cid" value={collection.cid}/>

                            <div className="text-center py-4 mt-3">
                                <button
                                    type="submit"
                                    className={this.state.btnStyle}
                                    disabled={this.state.buttonDisabled}
                                >
                                    Delete collection{" "}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}