import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class ProductMenu extends Component {
    state = {
        loading: true,
        categories: [],
        manufacturers: [],
        catManufacturers: [],
        Tiles: [],
        Adhesives: [],
        Furnitures: [],
        Faucets: [],
        "Toilets - Bidet": [],
        "Bath Tubs - Jacuzzi": [],
        "Shower Cabins": [],
        "Shower Tubs": [],
        Clothing: [],
        Bank: [],
        categoryBar: null,
        cats: {}
    };

    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({categories: data, loading: false});

        this.state.categories.forEach(man => {
            this.fetchCategoryManufacturer(man.category);
        });

        setTimeout(function() {
            if(document.getElementById("Faucets").innerText==="Rakordet")
            {
                document.getElementById("Faucets").innerText="Çeshma"
            }
            if(document.getElementById("Furnitures").innerText==="Orendi")
            {
                document.getElementById("Furnitures").innerText="Mobilje"
            }
        }, 1000);
    }

    async fetchCategoryManufacturer(e) {
        // console.log(e);
        fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${e}'`)
            .then(response => response.json())
            .then(data => {
                console.log("Manufacturers for " + e + ": " + JSON.stringify(data));
                if (data.length > 0) {
                    this.setState({
                        [e]: data,
                    })
                }
            });
    }

    render() {
        if (this.state.loading) {
            return <div className="lds-hourglass"/>
        }
        return (
            // <div className="nav-scroller py-1 mb-2">
            <nav className="navbar navbar-expand-lg navbar-light bg-light rounded">

                <div className="navbar-collapse justify-content-md-center" id="navbarsExample10">
                    <ul className="navbar-nav">
                        {
                            this.state.categories.map(man => {
                                let catManufacturers = man.category;
                                return (
                                    <li className="nav-item dropdown" key={man.category}>
                                        <a className="p-2 text-muted nav-link dropdown-toggle"
                                           href="/#"
                                           id={catManufacturers}
                                           data-toggle="dropdown"
                                            // onClick={this.fetchCategoryManufacturer.bind(this)}
                                           name={catManufacturers}
                                        >
                                            {catManufacturers}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdown10">
                                            {
                                                this.state[catManufacturers].map(mann => {
                                                    let link = '/product/' + man.category + '/' + mann.mname;
                                                    return (
                                                        <Link
                                                            to={link}
                                                            className="dropdown-item nav-item nav-link notranslate"
                                                            key={mann.mname}
                                                        >
                                                            {mann.mname}
                                                        </Link>
                                                    )
                                                })}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </nav>
        )
    }
}
