import React from "react";
import marazzi from '../../images/Partners/marazzi_480x480.png';
import rosan from '../../images/Partners/Rosan-768x768.jpg';
import kolpasan from '../../images/Partners/kolpasan.jpg';
import ardex from '../../images/Partners/ardex.jpg';
import grohe from '../../images/Partners/grohe.jpg';
import unitas from '../../images/Partners/unitas.jpg';
import shullani from '../../logo-white-resize.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import marcaCorona from "../../images/Partners/marca_corona1.png";

export default function Footer() {
    return (
        <footer className="py-4 bg-dark text-white footer-top">
            <div className="container">
                <br/>

                <div className="social-container">
                    <h3>Our Partners</h3>
                </div>

                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={2000}
                    centerMode={false}
                    className=""
                    containerClass="containerr"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 6
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 4
                        }
                    }}
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <img
                        src={marazzi}
                        alt="Marazzi"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={rosan}
                        alt="Rosan"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={marcaCorona}
                        alt="Marca Corona"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={ardex}
                        alt="Ardex"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={kolpasan}
                        alt="Kolpasan"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={grohe}
                        alt="Grohe"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                    <img
                        src={unitas}
                        alt="Unitas"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '100%'
                        }}
                    />
                </Carousel>

                <br/>
                <div className="row">
                    <div className="col-md-4 col-lg-4 footer-about wow fadeInUp animated text-center"
                         style={{visibility: "visible", animationName: "fadeInDown"}}>
                        <br/><br/>
                        <img className="mt-md-n5" src={shullani} alt="logo-footer" width={"450px"}/>
                        <br/>
                        <br/>
                    </div>


                    <div className="col-md-4 col-lg-4  footer-contact wow fadeInDown animated"
                         style={{visibility: "visible", animationName: "fadeInDown"}}>
                        <h3>Contact</h3>
                        <p><span className="fa fa-info"/> Contact us and we'll get back to
                            you within 24 hours on <a href="/about">About Us</a></p>
                        <p><span className="fa fa-phone"/> +389 76 223 113</p>
                        <p><span className="fa fa-envelope"/> Email: <a
                            href="mailto:info@shullani.com"> shullani.tetovo@gmail.com</a></p>
                        <p><span className="fa fa-map-marker"/> Tetovo, MK</p>
                    </div>


                    <div className="col-md-4 col-lg-4 footer-social wow fadeInUp animated"
                         style={{visibility: "visible", animationName: "fadeInDown"}}>
                        <h3>Follow us</h3>
                        <p>
                            <a href="https://www.facebook.com/shullaniceramics/"><span className="fa fa-facebook"/></a>
                            <a href="https://www.instagram.com/shullaniceramica/"><span
                                className="fa fa-instagram"/></a>
                        </p>
                    </div>
                </div>

                <br/>
                <p className="m-0 text-center text-white">
                    Copyright &copy; Shullani Ceramica {(new Date().getFullYear())} | SA
                </p>
            </div>
        </footer>
    );
}
