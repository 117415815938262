import React from "react";
import './form-submission-handler';

function handleText(e){
    let form = e.target;
    let formElements = form.querySelector(".form-elements");
    if (formElements) {
        formElements.style.display = "none"; // hide form
    }
    // document.getElementById("myForm").style.display="none";
    document.getElementById("thankyou_message").style.display="block";
}

export default function About() {
    return (


        <React.Fragment>
            <div id="contact" className="container bg-grey wow fadeIn animated">
                <h2 className="text-center">CONTACT</h2>
                <br/>
                <div className="row">
                    <div className="col-sm-5">
                        <p>
                            <span className="fa fa-info"/> Contact us and we'll get back to
                            you within 24 hours.
                        </p>
                        <p>
                            <span className="fa fa-map-marker"/> Tetovo, MK
                        </p>
                        <p>
                            <span className="fa fa-phone"/> +389 76 223 113
                        </p>
                        <p>
                            <span className="fa fa-envelope"/> shullani.tetovo@gmail.com
                        </p>
                    </div>
                    <div className="col-sm-7 slideanim">
                        <form className="gform pure-form pure-form-stacked"
                              method="POST"
                              id="myForm"
                              name="myForm"
                              onSubmit={handleText}
                              action="https://script.google.com/macros/s/AKfycbw4qtMnvnzGygfgsNJachpRo7GZ8FOlltf46pC9imlmRWBlgfQi/exec"
                              target="hiddenFrame">

                            <div className="form-elements">
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        <fieldset className="pure-group">
                                            <input className="form-control" id="name" name="name" type="text"
                                                   required placeholder="Name"/>
                                        </fieldset>
                                    </div>

                                    <div className="col-sm-6 form-group">
                                        <fieldset className="pure-group">
                                            <input className="form-control" id="email" name="email" type="text"
                                                   required placeholder="Phone number/email"/>
                                        </fieldset>
                                    </div>
                                </div>

                                <fieldset className="pure-group">
                                    <textarea className="form-control" id="message" name="message" rows="10"
                                              placeholder="Message..."/>
                                </fieldset>


                                {/*<fieldset className="pure-group honeypot-field">*/}
                                {/*    <label htmlFor="honeypot">To help avoid spam, utilize a Honeypot technique with a*/}
                                {/*        hidden text field; must be empty to submit the form! Otherwise, we assume the*/}
                                {/*        user is a spam bot.</label>*/}
                                {/*    <input id="honeypot" type="text" name="honeypot" value=""/>*/}
                                {/*</fieldset>*/}

                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <button className="btn btn-default pull-right" type="submit">
                                            <i className="fa fa-paper-plane"/>&nbsp;Send
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div id="thankyou_message" style={{display: "none"}} className="thankyou_message">
                                <h2><em>Thanks</em> for contacting us! We will get back to you soon!
                                </h2>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center container wow fadeInDown animated">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2965.328434789589!2d20.96015146632722!3d41.993226426650736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9480069168732062!2sShullani%20Ceramica!5e0!3m2!1sen!2smk!4v1608905409215!5m2!1sen!2smk"
                    width="90%"
                    height="450"
                    frameBorder="0"
                    style={{border: "0", position: "center"}}
                    allowFullScreen
                    title="iframe"
                />
            </div>
            <br/>
            {/*<div>*/}
            {/*    <iframe title="" name="hiddenFrame" width="0" height="0"  style={{display: "none"}}/>*/}
            {/*</div>*/}
        </React.Fragment>
    );
}