import React from 'react'
import Carousel from '../../carousel/Carousel'
import LatestCollections from '../LatestCollections'
import marazziLogo from '../../../images/Partners/marazzi_840x660.jpg';
import ardexLogo from '../../../images/Partners/ardex.jpg'
import kolpasanLogo from '../../../images/Partners/kolpasan.jpg'
import groheLogo from '../../../images/Partners/grohe.jpg'
import unitasLogo from '../../../images/Partners/unitas.jpg'
import marcaCoronaLogo from '../../../images/Partners/marca_corona1.png'

export default function Home() {
    return (
        <div>
            <Carousel/>
            <div className="container" style={{paddingTop: '2%'}}>

                {/*Section 1*/}
                <div className="section-1-container section-container">
                    <div className="container">
                        <div className="row">
                            <div className="col section-1 section-description wow fadeIn animated"
                                 style={{visibility: "visible", animationName: "fadeIn"}}>
                                <h2>Who are our partners?</h2>
                                <div className="divider-1 wow fadeInUp animated"
                                     style={{visibility: "visible", animationName: "fadeInUp"}}>
                                    <span/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 section-1-box wow fadeInUp animated"
                                 style={{visibility: "visible", animationName: "fadeInUp"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={marazziLogo} className="rounded-circle" alt="marazziLogo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3>Marazzi</h3>
                                        <p>Marazzi was foundedin 1935 at Sassuolo. Marazzi is the ceramic tile
                                            industry's best known brand. Present in more than
                                            140 countries and symbolises the best of Italian style in the interior
                                            decoration and design sector. Marazzi has been responsible for the main
                                            technological, process and design innovations in the ceramic tile industry –
                                            some of which have become major milestones in the history of modern
                                            ceramics.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 section-1-box wow fadeInDown animated"
                                 style={{visibility: "visible", animationName: "fadeInDown"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={ardexLogo} className="rounded-circle" alt="ardexLogo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3>Ardex</h3>
                                        <p>ARDEX Group is the leading solution and service provider of innovative
                                            building systems.”
                                            For over 70 years, ARDEX has been the quality leader for an entire industry,
                                            offering building
                                            chemicals with supreme reliability – the brand of choice for installers and
                                            wholesalers.
                                            This also shows in our systems, which are optimally matched and extremely
                                            cost-effective thanks
                                            to their ease and speed of processing and their high coverage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 section-1-box wow fadeInUp animated"
                                 style={{visibility: "visible", animationName: "fadeInUp"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={kolpasanLogo} className="rounded-circle " alt="kolpasanLogo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3 className="notranslate">Kolpasan</h3>
                                        <p>Kolpa san is renowned not only on the Slovenian market, but in more than 44
                                            countries.
                                            Innovation, boldness, modernity and top notch quality are the main strengths
                                            of Kolpasan,
                                            which can be seen also in their own brands – the line of high quality
                                            bathroom equipment
                                            Kolpa san and the composite materials programme Kerrock, which delight even
                                            the most demanding clients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 section-1-box wow fadeInUp animated "
                                 style={{visibility: "visible", animationName: "fadeInUp"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={marcaCoronaLogo} className="rounded-circle" alt="marca-corona-logo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3>Marca Corona</h3>
                                        <p>Marca Corona is a brand that proudly represents the values and the quality of Italian 
                                            ceramics since 1741. Discover an eclectic and contemporary proposal of porcelain 
                                            stoneware walls and floors and white-body coverings: Italian porcelain modern tiles for 
                                            bathroom, kitchen, living, and outdoor, designed to furnish all environments in the 
                                            residential or contract spaces with passion and personality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 section-1-box wow fadeInDown animated"
                                 style={{visibility: "visible", animationName: "fadeInDown"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={groheLogo} className="rounded-circle" alt="groheLogo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3>Grohe</h3>
                                        <p>GROHE is a leading global brand for complete bathroom solutions and kitchen
                                            fittings and has a total of over 6,000 employees, 2,400 of which are based
                                            in Germany. In the last ten years alone, GROHE has received over 300 design
                                            and innovation awards as well as several top rankings as one of “Germany’s
                                            most sustainable large brands”. GROHE was the first in its industry to win
                                            the German government’s CSR prize and was also featured in the renowned
                                            Fortune®
                                            magazine’s ranking of Top 50 that are “Changing the World”.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 section-1-box wow fadeInUp animated"
                                 style={{visibility: "visible", animationName: "fadeInUp"}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*<div className="section-1-box-icon">*/}
                                        <img src={unitasLogo} className="rounded-circle" alt="unitasLogo"/>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-8">
                                        <h3 className="notranslate">Unitas</h3>
                                        <p>Sanitary mixers HERZ/UNITAS are presented in 75 countries worldwide. With the
                                            support of new investments, technologies and know how, the company has grown
                                            and developed since 1933. The highest quality of our products is confirmed
                                            by all major necessary European certificates: SIST EN200, SIST EN246, SIST
                                            EN248, SIST EN817, SIST EN1112 and SIST EN1113.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*//////////////// section 2 //////////////////////////*/}
                {/* <div className="section-2-container section-container section-container-gray-bg rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col section-2-box wow fadeInLeft animated"
                                 style={{visibility: "visible", animationName: "fadeInLeft"}}>
                                <h3>Shullani Ceramica</h3>
                                <p className="medium-paragraph">
                                    Our brand
                                </p>
                                <p>
                                    Shullani Ceramica is founded on 2007 by EuroAtlantik DOOEL which owns also Concept
                                    Studio brand. We are the official distributor of well known worldwide
                                    brands such as Marazzi, Ardex Austria, Grohe and Kolpasan.
                                </p>
                                <p>
                                    Our products and work can be found on <a href="/projects">Our Projects</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <LatestCollections/>
                <br/>
            </div>
        </div>
    )
}
