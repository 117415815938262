import React from "react";

export default function Carousel() {
    return (
        <header>
            <div
                id="carouselExampleIndicators"
                className="carousel slide wow fadeIn animated"
                data-ride="carousel"
            >
                <ol className="carousel-indicators">
                    <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        className="active"
                    />
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"/>
                </ol>
                <div className="carousel-inner" role="listbox">
                    <div
                        className="carousel-item active"
                        style={{
                            backgroundImage: `url(${require("../../half-sliders/marazzi_crogiolo_lume_008.jpg").default})`
                        }}
                    >
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="carouselItem" id="FirstHeading">Lume</h3>
                            <p className="carouselItem" id="SecondHeading">
                                Variations in colour and patterning create interplays of light which add vibrancy to spaces.
                            </p>
                        </div>
                    </div>
                    <div
                        className="carousel-item"
                        style={{
                            backgroundImage: `url(${require("../../half-sliders/generated_Multiforme_1741_Bathroom_Opale_Green_Ama_Mica_AsP0q1f.jpg.1400x1400_q85.jpg").default})`
                        }}
                    >
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="carouselItem" id="FirstHeading">
                                Multiforme 1741
                            </h3>
                            <p className="carouselItem" id="SecondHeading">
                                Freedom in composition with retro tiles in a contemporary style.{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="carousel-item"
                        style={{
                            backgroundImage: `url(${require("../../half-sliders/marazzi_vivo_002.jpg").default})`
                        }}
                    >
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="carouselItem" id="FirstHeading">
                                Vivo
                            </h3>
                            <p className="carouselItem" id="SecondHeading">
                                With this variety and an aesthetic appeal enhanced by a slightly glossy surface that
                                underlines the beauty of the wood look, Vivo offers great freedom of application.
                            </p>
                        </div>
                    </div>
                    <div
                        className="carousel-item"
                        style={{
                            backgroundImage: `url(${require("../../half-sliders/marazzi_crogiolo_luz_001.jpg").default})`
                        }}
                    >
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="carouselItem" id="FirstHeading">
                                Luz
                            </h3>
                            <p className="carouselItem" id="SecondHeading">
                                The small-size Luz collection, in porcelain stoneware finished with a super-glossy
                                glaze, is styled to resemble traditional Portuguese hand-glazed tiles.
                            </p>
                        </div>
                    </div>
                    <div
                        className="carousel-item"
                        style={{
                            backgroundImage: `url(${require("../../half-sliders/generated_MarcaCorona_Panella-Milano_Terra_18.jpg.1400x1400_q85.jpg").default})`
                        }}
                    >
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="carouselItem" id="FirstHeading">
                                Terra
                            </h3>
                            <p className="carouselItem" id="SecondHeading">
                                Hecaon tiles and the great beauty of terracotta.
                            </p>
                        </div>
                    </div>
                </div>
                <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                    <span className="sr-only">Previous</span>
                </a>
                <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </header>
    );
}
