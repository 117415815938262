import React, {Component} from "react";
import ProductThumbnail from "./ProductThumbnail";
import ImageGallery from "react-image-gallery";
import noImage from "../../../images/no-image.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../../mdb-master/js/script.js";
import "../../../scripts.js";

export default class ManufacturerProducts extends Component {
    state = {
        products: null,
        collection: [
            {
                name: "",
                sizes: "",
            },
            ],
        collectionName: "",
        category: this.props.category,
        mname: this.props.manufacturer,
        cid: null,
        isFetching: false,
        hasImages: false,
        images: [],
        error: null
    };

    fetchCollection = () => {
        fetch(
            `${process.env.REACT_APP_API_SERVER_IP}collection/get_collection.php?cid=${this.state.cid}`
        )
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    this.setState({collection: null, isFetching: false});
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                data.forEach((item) => {
                    this.setState({
                        collection: data,
                        images: []
                    });
                    for (const [key, value] of Object.entries(item)) {
                        if (key.includes("image")) {
                            if (value) {
                                console.log(key);
                                console.log(value);

                                this.state.images.push({
                                    original: String('https://lh3.googleusercontent.com/d/' + value),
                                    thumbnail: String('https://lh3.googleusercontent.com/d/' + value),
                                });
                                this.setState({hasImages: true});
                            }
                        }
                    }
                });
            })

    };

    fetchData = () => {
        this.setState({isFetching: true});
        // get collection products
        fetch(
            `${process.env.REACT_APP_API_SERVER_IP}product/get_category_manufacturer_products.php?category=${this.props.category}&manufacturer=${this.props.manufacturer}`
        )
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    this.setState({products: null, isFetching: false});
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data =>
                this.setState({
                    products: data,
                    collectionName: data[0].collection,
                    cid: data[0].cid,
                    isFetching: !this.state.isFetching
                }, () => {
                    this.fetchCollection();
                })
            )
            .catch(error => this.setState({error, products: null, isFetching: false}));
    };

    componentDidMount() {
        this.fetchData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.fetchData();
        }
    }

    render() {
        if (this.state.isFetching) return <div className="lds-hourglass"/>;
        if (!this.state.products) {
            return (
                <React.Fragment>
                    <h3 className="my-4 text-center text-lg-center wow fadeInUp animated">
                        No products were found!
                    </h3>
                    <div className="backButton wow fadeInUp animated">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => window.history.back()}
                        >
                            ←
                        </button>
                    </div>
                </React.Fragment>
            );
        }

        const collection = this.state.collection[0];

        let collectionDescription = null;
        let collectionSizes = null;
        let collectionColours = null;
        let collectionSurfaces = null;

        if(collection.description){
            collectionDescription =
                <div className="section-2-container section-container-grayer pb-3 font-italic rounded wow fadeInLeft animated">
                    <div className="container">
                        <div className="row">
                            <div className="col section-2-box  d-flex justify-content-center"
                                 style={{visibility: "visible", animationName: "fadeInLeft"}}>
                                    {collection.description}
                             </div>
                         </div>
                     </div>
                </div>
        }

        if(collection.sizes){
            collectionSizes =
                <div className="wow fadeInLeft animated">
                    <div className="col align-self-center"><h4 className="d-flex justify-content-center ">Sizes</h4></div>

                    <div className="d-flex justify-content-center">
                        {/*<div className="border" style={{width: "24px", height: "6px"}}/>*/}
                        <div>{collection.sizes}</div>
                    </div>
                </div>
        }

        if(collection.colours){
            collectionColours =
                <div className="wow fadeInLeft animated">
                    <div className="col align-self-center"><h4 className="d-flex justify-content-center ">Colors</h4></div>
                    <div className="d-flex justify-content-center">
                        {/*<div className="border" style={{width: "24px", height: "6px"}}/>*/}
                        <div>{collection.colours}</div>
                    </div>
                </div>
        }

        if(collection.surfaces){
            collectionSurfaces =
                <div className="wow fadeInLeft animated">
                    <div className="col align-self-center"><h4 className="d-flex justify-content-center ">Surfaces</h4></div>
                    <div className="d-flex justify-content-center">
                        {/*<div className="border" style={{width: "24px", height: "6px"}}/>*/}
                        <div>{collection.surfaces}</div>
                    </div>
                </div>
        }

        return (
            <React.Fragment>
                <h2 className="my-4 text-center text-lg-center wow fadeIn animated">
                    {this.props.manufacturer} {this.props.category}
                </h2>

                <div className="container">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            {/*<h2 className="d-flex justify-content-center">Sizes</h2>*/}
                            {collectionDescription}
                            <div>
                                {this.state.hasImages ? (
                                    <ImageGallery items={this.state.images} onErrorImageURL={noImage}/>
                                ) : (
                                    <br/>
                                )
                                }
                            </div>
                            <br/>
                            {collectionSizes}
                            <br/>
                            {collectionColours}
                            <br/>
                            {collectionSurfaces}
                            <div className="backButton wow fadeInUp animated">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => window.history.back()}
                                >
                                    ←
                                </button>
                            </div>
                            <div className="row text-center text-lg-left wow fadeInUp animated">
                                {
                                    this.state.products.map(product => (
                                            <ProductThumbnail
                                                key={product.id}
                                                product={product}
                                                category={this.props.category}
                                                mname={this.props.manufacturer}
                                                cid={this.state.cid}
                                                id={this.props.match.params.id}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
