import React, {Component} from 'react';
import floorUsage from '../../../images/icons/icon_pavimento.jpg';
import wallUsage from '../../../images/icons/icon_rivestimento.jpg';
// import greenn from '../../../images/icons/icon_certificazioni_greenguard.png';
import green from '../../../images/icons/greenguard.png';
import frost from '../../../images/icons/frost_proof.jpg';
import NSF from '../../../images/icons/icon_nsf.png';
import LEED from '../../../images/icons/icon_certificazione_leed.png';
import Stepwise from '../../../images/icons/icon_stepwise.png';
import Bodyplus from '../../../images/icons/icon_bodyplus.png';
import Puro from '../../../images/icons/icon_puro.png';
import ecolabel from '../../../images/icons/ecolabel.png'
import ImageGallery from 'react-image-gallery';
import "../../../mdb-master/js/script.js";
import "../../../scripts.js";
// import defaultPicture from '../../../images/defaultPicture.png';
import noImage from '../../../images/no-image.png';
// CSS
import "react-image-gallery/styles/css/image-gallery.css";


class ProductSpecs extends Component {
    state = {
        loading: true,
        product: null,
        category: this.props.match.params.category,
        mname: this.props.match.params.manufacturer,
        cid: this.props.match.params.cid,
        images: [],
        hasImages: false,
        hasTechnicalDraw: false,
        technicalDrawing: []
    };


    async componentDidMount() {
        const url = `${process.env.REACT_APP_API_SERVER_IP}product/get_product.php?id=${this.props.match.params.id}`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({product: data, loading: false});
        console.log(this.state);
        // for (let prop in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, prop)) {
        //        console.log(prop);
        //     }
        // }


        // Object.keys(data[0]).forEach(function (key, index) {
        //     console.log(key);
        //     console.log(data[0][key]);
        //     // if (key.includes("image")) {
        //     //     this.setState(previousState => ({
        //     //         images: [...previousState.images[0],
        //     //             {
        //     //                 original: 'https://drive.google.com/uc?export=view&id=' + data[0][key],
        //     //                 thumbnail: "https://drive.google.com/uc?export=view&id=" + data[0][key],
        //     //             },
        //     //         ]
        //     //     }));
        //     // }
        //
        //
        //     if (key.includes("image")) {
        //         this.setState({ images: [...prevstate.images, {
        //                                 original: 'https://drive.google.com/uc?export=view&id=' + data[0][key],
        //                                 thumbnail: "https://drive.google.com/uc?export=view&id=" + data[0][key],
        //                             },] })
        //     }
        // });


        data.forEach((item) => {
            console.log(item);
            for (const [key, value] of Object.entries(item)) {
                // console.log(`${key}: ${value}`);

                if (key.includes("image")) {
                    if (value) {
                        console.log(key);
                        console.log(value);

                        this.state.images.push({
                            original: String('https://lh3.googleusercontent.com/d/' + value),
                            thumbnail: String('https://lh3.googleusercontent.com/d/' + value),
                        });
                        this.setState({hasImages: true});
                    }
                }


                if (key === "technicalDrawing" && value) {
                    this.state.technicalDrawing.push({
                        original: String('https://lh3.googleusercontent.com/d/' + value)
                    });
                    this.setState({hasTechnicalDraw: true});
                }
            }
        });


        console.log(this.state);
        window.scrollTo(0, 0);
    };


    render() {
        if (this.state.loading) return <div className="lds-hourglass"/>;
        const product = this.state.product[0];
        console.log(this.state.product);

        let productTechnology = null;
        let productThickness = null;
        let productPlacing = null;
        let productSpecifications = null;
        let productRoom = null;
        let productColour = null;
        let productSize = null;
        let productQuality = null;

        // technology
        if (product.technology) {
            productTechnology =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Technology</strong></th>
                    <td><strong>{product.technology}</strong></td>
                </tr>;
        }

        // quality
        if (product.quality) {
            productQuality =
                // <tr>
                //     <th className="pl-0 w-25" scope="row"><strong>Quality</strong></th>
                //     <td className="font-weight-bold blue-text"><strong>{product.quality}</strong></td>
                // </tr>;
                <small className="font-weight-bold blue-text"><strong> {product.quality}</strong></small>;
        }


        // thickness
        if (product.thickness) {
            productThickness =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Thickness</strong></th>
                    <td><strong>{product.thickness}</strong></td>
                </tr>;
        }

        // colour
        if (product.colour) {
            productColour =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Color</strong></th>
                    <td><strong>{product.colour}</strong></td>
                </tr>
        }

        // size
        if (product.size) {
            productSize =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Size</strong></th>
                    <td><strong>{product.size}</strong></td>
                </tr>;
        }

        // placing
        if (product.placing === "Floor") {
            productPlacing =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Use</strong></th>
                    <td>
                        Floor&ensp;
                        <img
                            title="Floor"
                            alt="Floor"
                            src={floorUsage}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.placing === "Wall") {
            productPlacing =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Use</strong></th>
                    <td>
                        Wall&ensp;
                        <img
                            title="Wall"
                            alt={"wall"}
                            src={wallUsage}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.placing === "FloorWall") {
            productPlacing =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Use</strong></th>
                    <td>
                        Wall&ensp;
                        <img
                            title="Wall"
                            alt={"wall"}
                            src={wallUsage}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Floor&ensp;
                        <img
                            title="Floor"
                            alt={"floor"}
                            src={floorUsage}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else {
            productPlacing = null;
        }


        // specifications
        if (product.specifications === "Greenguard") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        Greenguard Certification&ensp;
                        <img
                            alt="Greenguard Specification"
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "GreenFrost") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Specifications</strong></th>
                    <td>
                        Greenguard Certification&ensp;
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        &ensp; Frost Proof&ensp;
                        <img
                            alt={"Frost Proof"}
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "NSF") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        NSF &ensp;
                        <img
                            alt="NSF Specification"
                            src={NSF}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "Frost") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        FrostProof &ensp;
                        <img
                            alt="Frost Specification"
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED &ensp;
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Greenguard") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED &ensp;
                        <img
                            alt="LEED,Greenguard Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Greenguard Certification
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Frost,Greenguard") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Frost Proof
                        <img
                            alt={"Frost Proof"}
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Greenguard Certification
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Frost,Greenguard,Stepwise") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Frost Proof
                        <img
                            alt={"Frost Proof"}
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Greenguard Certification
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        Stepwise
                        <img
                            alt={"Stepwise"}
                            src={Stepwise}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Puro") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        Puro
                        <img
                            alt={"Puro"}
                            src={Puro}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Frost,Puro") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Frost Proof
                        <img
                            alt={"Frost Proof"}
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        Puro
                        <img
                            alt={"Puro"}
                            src={Puro}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "LEED,Frost,Stepwise,Puro") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        LEED
                        <img
                            alt="LEED Specification"
                            src={LEED}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Frost Proof
                        <img
                            alt={"Frost Proof"}
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        &ensp;Stepwise
                        <img
                            alt={"Stepwise"}
                            src={Stepwise}
                            className="img-fluid z-depth-1"
                        />
                        Puro
                        <img
                            alt={"Puro"}
                            src={Puro}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "Ecolabel,Greenguard") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        Ecolabel&ensp;
                        <img
                            alt="Ecolabel Specification"
                            src={ecolabel}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        <br/>Greenguard Certification&ensp;
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "Ecolabel,Frost,Greenguard") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        Ecolabel&ensp;
                        <img
                            alt="Ecolabel Specification"
                            src={ecolabel}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        <br/>
                        Frost Proof&ensp;
                        <img
                            alt="Frost Proof"
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        <br/>
                        Greenguard Certification&ensp;
                        <img
                            alt="GreenGuard"
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "Frost,Greenguard,Stepwise") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        Frost Proof&ensp;
                        <img
                            alt="Frost Proof"
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        <br/>
                        Greenguard Certification&ensp;
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        <br/>
                        Stepwise&ensp;
                        <img
                            alt={"Stepwise"}
                            src={Stepwise}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        } else if (product.specifications === "Frost,Greenguard,Stepwise,Bodyplus") {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-25" scope="row"><strong>Certifications</strong></th>
                    <td>
                        Frost Proof&ensp;
                        <img
                            alt="Frost Proof"
                            src={frost}
                            className="img-fluid z-depth-1"
                        />
                        <br/>
                        Greenguard Certification&ensp;
                        <img
                            alt={"GreenGuard"}
                            src={green}
                            className="img-fluid z-depth-1"
                            height="50"
                            width="50"
                        />
                        <br/>
                        Stepwise&ensp;
                        <img
                            alt={"Stepwise"}
                            src={Stepwise}
                            className="img-fluid z-depth-1"
                        />
                        <br/>
                        Bodyplus&ensp;
                        <img
                            alt="Bodyplus"
                            src={Bodyplus}
                            className="img-fluid z-depth-1"
                        />
                    </td>
                </tr>
        }else if (product.specifications) {
            productSpecifications =
                <tr>
                    <th className="pl-0 w-200" scope="row"><strong>Specifications</strong></th>
                    <td><strong>{product.specifications}</strong></td>
                </tr>;
        } else {
            productSpecifications = null;
        }

        // Room
        if (product.room) {
            productRoom =
                <tr>
                    <th className="pl-0 w-200" scope="row"><strong>Room</strong></th>
                    <td><strong>{product.room}</strong></td>
                </tr>
        }

        // let hasImages = true;
        // if(this.state.images=== undefined || this.state.images.length === 0)
        //      hasImages = false;

        // let hasTechnicalDraw = true;
        // if(this.state.technicalDrawing=== undefined || this.state.technicalDrawing.length === 0)
        //     hasTechnicalDraw = false;

        return (
            <div id="start" className="container wow fadeInDown animated">
                <div className="backButton">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ←
                    </button>
                </div>
                <section className="mt-4">

                    <div className="row">
                        <div className="col-md-6 mb-4 mb-md-0">
                            <div>
                                {this.state.hasImages ? (
                                    <ImageGallery items={this.state.images} onErrorImageURL={noImage}/>
                                ) : (
                                    <br/>
                                )
                                }
                            </div>
                            <br/>
                            <div>
                                {this.state.hasTechnicalDraw ? (
                                    <ImageGallery items={this.state.technicalDrawing} infinite={false} lazyLoad={true}
                                                  showThumbnails={false} showPlayButton={false}
                                                  onErrorImageURL={noImage}/>
                                ) : (
                                    <br/>
                                )
                                }
                            </div>

                        </div>
                        <div className="col-md-6">

                            <h5>{product.name} {productQuality}</h5>
                            <p className="mb-2 text-muted text-uppercase small">{this.state.category}</p>
                            {/*<ul className="rating">*/}
                            {/*    <li>*/}
                            {/*        <span className="fa fa-star fa-sm text-primary"/>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <span className="fa fa-star fa-sm text-primary"/>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <span className="fa fa-star fa-sm text-primary"/>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <span className="fa fa-star fa-sm text-primary"/>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <span className="fa fa-star-half-o fa-sm text-primary"/>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            {/*<p><span className="mr-1"><strong>$12.99</strong></span></p>*/}
                            <p className="pt-1">{product.description}</p>
                            <div className="table-responsive">
                                <table className="table table-sm table-borderless mb-0">
                                    <tbody>
                                    <tr>
                                        <th className="pl-0 w-25" scope="row"><strong>Product ID</strong></th>
                                        <td><strong>{product.pid}</strong></td>
                                    </tr>
                                    <tr>
                                        <th className="pl-0 w-25" scope="row"><strong>Manufacturer</strong></th>
                                        <td><strong>{this.state.mname}</strong></td>
                                    </tr>
                                    <tr>
                                        <th className="pl-0 w-25" scope="row"><strong>Collection</strong></th>
                                        <td><strong>{product.collection}</strong></td>
                                    </tr>
                                    {productColour}
                                    {productSize}
                                    {productTechnology}
                                    {productThickness}
                                    {productPlacing}
                                    {productSpecifications}
                                    {productRoom}
                                    </tbody>
                                </table>
                            </div>
                            <hr/>
                            {/*<div className="table-responsive mb-2">*/}
                            {/*    <table className="table table-sm table-borderless">*/}
                            {/*        <tbody>*/}
                            {/*        <tr>*/}
                            {/*            <td className="pl-0 pb-0 w-25">Quantity</td>*/}
                            {/*            <td className="pb-0">Select size</td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td className="pl-0">*/}
                            {/*                <div className="def-number-input number-input safari_only mb-0">*/}
                            {/*                    <button*/}
                            {/*                        onClick="this.parentNode.querySelector('input[type=number]').stepDown()"*/}
                            {/*                        className="minus"/>*/}
                            {/*                    <input className="quantity" min="0" name="quantity" value="1"*/}
                            {/*                           type="number"/>*/}
                            {/*                    <button*/}
                            {/*                        onClick="this.parentNode.querySelector('input[type=number]').stepUp()"*/}
                            {/*                        className="plus"/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="mt-1">*/}
                            {/*                    <div className="form-check form-check-inline pl-0">*/}
                            {/*                        <input type="radio" className="form-check-input" id="small"*/}
                            {/*                               name="materialExampleRadios"*/}
                            {/*                               checked/>*/}
                            {/*                        <label*/}
                            {/*                            className="form-check-label small text-uppercase card-link-secondary"*/}
                            {/*                            htmlFor="small">Small</label>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="form-check form-check-inline pl-0">*/}
                            {/*                        <input type="radio" className="form-check-input" id="medium"*/}
                            {/*                               name="materialExampleRadios"/>*/}
                            {/*                        <label*/}
                            {/*                            className="form-check-label small text-uppercase card-link-secondary"*/}
                            {/*                            htmlFor="medium">Medium</label>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="form-check form-check-inline pl-0">*/}
                            {/*                        <input type="radio" className="form-check-input" id="large"*/}
                            {/*                               name="materialExampleRadios"/>*/}
                            {/*                        <label*/}
                            {/*                            className="form-check-label small text-uppercase card-link-secondary"*/}
                            {/*                            htmlFor="large">Large</label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}

                            {/*<button type="button" className="btn btn-primary btn-md mr-1 mb-2">Buy now</button>*/}
                            {/*<button type="button" className="btn btn-light btn-md mr-1 mb-2">*/}
                            {/*    <i className="fas fa-shopping-cart pr-2"></i>*/}
                            {/*    Add to cart*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </section>

                {/*<div className="classNameic-tabs">*/}

                {/*    <ul className="nav tabs-primary nav-justified" id="advancedTab" role="tablist">*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link show active" id="description-tab" data-toggle="tab"*/}
                {/*               href="#description"*/}
                {/*               role="tab" aria-controls="description" aria-selected="true">Description</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link" id="info-tab" data-toggle="tab" href="#info" role="tab"*/}
                {/*               aria-controls="info"*/}
                {/*               aria-selected="false">Information</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link" id="reviews-tab" data-toggle="tab" href="#reviews" role="tab"*/}
                {/*               aria-controls="reviews" aria-selected="false">Reviews (1)</a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*    <div className="tab-content" id="advancedTabContent">*/}
                {/*        <div className="tab-pane fade active show" id="description" role="tabpanel"*/}
                {/*             aria-labelledby="description-tab">*/}
                {/*            <h5>Product Description</h5>*/}
                {/*            <p className="small text-muted text-uppercase mb-2">Shirts</p>*/}
                {/*            <ul className="rating">*/}
                {/*                <li>*/}
                {/*                    <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*            <h6>12.99 $</h6>*/}
                {/*            <p className="pt-1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam,*/}
                {/*                sapiente illo. Sit*/}
                {/*                error voluptas repellat rerum quidem, soluta enim perferendis voluptates laboriosam.*/}
                {/*                Distinctio,*/}
                {/*                officia quis dolore quos sapiente tempore alias.</p>*/}
                {/*        </div>*/}
                {/*        <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">*/}
                {/*            <h5>Additional Information</h5>*/}
                {/*            <table className="table table-striped table-bordered mt-3">*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row" className="w-150 dark-grey-text h6">Weight</th>*/}
                {/*                    <td><em>0.3 kg</em></td>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row" className="w-150 dark-grey-text h6">Dimensions</th>*/}
                {/*                    <td><em>50 × 60 cm</em></td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </div>*/}
                {/*        <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">*/}
                {/*            <h5><span>1</span> review for <span>Fantasy T-shirt</span></h5>*/}
                {/*            <div className="media mt-3 mb-4">*/}
                {/*                <img className="d-flex mr-3 z-depth-1"*/}
                {/*                     src="https://mdbootstrap.com/img/Photos/Others/placeholder1.jpg" width="62"*/}
                {/*                     alt="Generic placeholder image"/>*/}
                {/*                <div className="media-body">*/}
                {/*                    <div className="d-flex justify-content-between">*/}
                {/*                        <p className="mt-1 mb-2">*/}
                {/*                            <strong>Marthasteward </strong>*/}
                {/*                            <span>– </span><span>January 28, 2020</span>*/}
                {/*                        </p>*/}
                {/*                        <ul className="rating mb-0">*/}
                {/*                            <li>*/}
                {/*                                <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                            </li>*/}
                {/*                            <li>*/}
                {/*                                <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                            </li>*/}
                {/*                            <li>*/}
                {/*                                <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                            </li>*/}
                {/*                            <li>*/}
                {/*                                <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                            </li>*/}
                {/*                            <li>*/}
                {/*                                <span className="fa fa-star fa-sm text-primary"></span>*/}
                {/*                            </li>*/}
                {/*                        </ul>*/}
                {/*                    </div>*/}
                {/*                    <p className="mb-0">Nice one, love it!</p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <hr/>*/}
                {/*            <h5 className="mt-4">Add a review</h5>*/}
                {/*            <p>Your email address will not be published.</p>*/}
                {/*            <div className="my-3">*/}
                {/*                <ul className="rating mb-0">*/}
                {/*                    <li>*/}
                {/*                        <a href="#!">*/}
                {/*                            <span className="fa fa-star fa-sm text-primary"/>*/}
                {/*                        </a>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <a href="#!">*/}
                {/*                            <span className="fa fa-star fa-sm text-primary"/>*/}
                {/*                        </a>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <a href="#!">*/}
                {/*                            <span className="fa fa-star fa-sm text-primary"/>*/}
                {/*                        </a>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <a href="#!">*/}
                {/*                            <span className="fa fa-star fa-sm text-primary"/>*/}
                {/*                        </a>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <a href="#!">*/}
                {/*                            <span className="fa fa-star fa-sm text-primary"/>*/}
                {/*                        </a>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*
                {/*                <div className="md-form md-outline">*/}
                {/*                        <textarea id="form76" className="md-textarea form-control pr-6"*/}
                {/*                                  rows="4"/>*/}
                {/*                    <label htmlFor="form76">Your review</label>*/}
                {/*                </div>*/}
                {/*
                {/*                <div className="md-form md-outline">*/}
                {/*                    <input type="text" id="form75" className="form-control pr-6"/>*/}
                {/*                    <label htmlFor="form75">Name</label>*/}
                {/*                </div>*/}
                {/*
                {/*                <div className="md-form md-outline">*/}
                {/*                    <input type="email" id="form77" className="form-control pr-6"/>*/}
                {/*                    <label htmlFor="form77">Email</label>*/}
                {/*                </div>*/}
                {/*                <div className="text-right pb-2">*/}
                {/*                    <button type="button" className="btn btn-primary waves-effect waves-light">Add a*/}
                {/*                        review*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<hr/>*/}

                {/*<section className="text-center">*/}

                {/*    <h4 className="text-center my-5"><strong>Related products</strong></h4>*/}


                {/*    <div className="row">*/}


                {/*        <div className="col-md-6 col-lg-3 mb-5">*/}


                {/*            <div className="">*/}

                {/*                <div className="view zoom overlay z-depth-2 rounded">*/}
                {/*                    <img className="img-fluid w-100"*/}
                {/*                         src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/12a.jpg"*/}
                {/*                         alt="Sample"/>*/}
                {/*                    <a href="#!">*/}
                {/*                        <div className="mask waves-effect waves-light">*/}
                {/*                            <img className="img-fluid w-100"*/}
                {/*                                 src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/12.jpg"/>*/}
                {/*                            <div*/}
                {/*                                className="mask rgba-black-slight waves-effect waves-light"></div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}

                {/*                <div className="pt-4">*/}

                {/*                    <h5>Blue denim shirt</h5>*/}
                {/*                    <p><span><strong>$17.99</strong></span></p>*/}

                {/*                </div>*/}

                {/*            </div>*/}


                {/*        </div>*/}

                {/*        <div className="col-md-6 col-lg-3 mb-5">*/}


                {/*            <div className="">*/}

                {/*                <div className="view zoom overlay z-depth-2 rounded">*/}
                {/*                    <img className="img-fluid w-100"*/}
                {/*                         src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/13a.jpg"*/}
                {/*                         alt="Sample"/>*/}
                {/*                    <a href="#!">*/}
                {/*                        <div className="mask waves-effect waves-light">*/}
                {/*                            <img className="img-fluid w-100"*/}
                {/*                                 src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/13.jpg"/>*/}
                {/*                            <div*/}
                {/*                                className="mask rgba-black-slight waves-effect waves-light"/>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}

                {/*                <div className="pt-4">*/}

                {/*                    <h5>Red hoodie</h5>*/}
                {/*                    <p><span><strong>$35.99</strong></span></p>*/}

                {/*                </div>*/}

                {/*            </div>*/}


                {/*        </div>*/}

                {/*        <div className="col-md-6 col-lg-3 mb-5">*/}


                {/*            <div className="">*/}
                {/*                <div className="view zoom overlay z-depth-2 rounded">*/}
                {/*                    <img className="img-fluid w-100"*/}
                {/*                         src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14a.jpg"*/}
                {/*                         alt="Sample"/>*/}
                {/*                    <h4 className="mb-0"><span*/}
                {/*                        className="badge badge-primary badge-pill badge-news">Sale</span>*/}
                {/*                    </h4>*/}
                {/*                    <a href="#!">*/}
                {/*                        <div className="mask waves-effect waves-light">*/}
                {/*                            <img className="img-fluid w-100"*/}
                {/*                                 src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14.jpg"/>*/}
                {/*                            <div*/}
                {/*                                className="mask rgba-black-slight waves-effect waves-light"></div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*                <div className="pt-4">*/}

                {/*                    <h5>Grey sweater</h5>*/}
                {/*                    <p><span className="text-danger mr-1"><strong>$21.99</strong></span><span*/}
                {/*                        className="text-grey"><strong><s>$36.99</s></strong></span>*/}
                {/*                    </p>*/}

                {/*                </div>*/}
                {/*            </div>*/}


                {/*        </div>*/}

                {/*        <div className="col-md-6 col-lg-3 mb-5">*/}
                {/*            <div className="">*/}
                {/*                <div className="view zoom overlay z-depth-2 rounded">*/}
                {/*                    <img className="img-fluid w-100"*/}
                {/*                         src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/15a.jpg"*/}
                {/*                         alt="Sample"/>*/}
                {/*                    <a href="#!">*/}
                {/*                        <div className="mask waves-effect waves-light">*/}
                {/*                            <img className="img-fluid w-100"*/}
                {/*                                 src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14.jpg"/>*/}
                {/*                            <div*/}
                {/*                                className="mask rgba-black-slight waves-effect waves-light"/>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*                <div className="pt-4">*/}
                {/*                    <h5>Black denim jacket</h5>*/}
                {/*                    <p><strong>$99.99</strong></p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</section>*/}
            </div>
        )
    }
}

export default ProductSpecs