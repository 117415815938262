import React, {Component} from 'react'
import Redirect from "react-router-dom/Redirect";

export default class AddCollection extends Component {
    state = {
        loading: true,
        categories: [],
        manufacturers: [],
        collections: [],
        exists: false,
        buttonDisabled: false,
        btnStyle: 'btn btn-md btn-success'
    };

    selectDefaultManufacturer() {
        console.log("default passed");
        let element = document.getElementById("manufacturerSelect");
        element.value = "";
        console.log(element.value);
    }

    async componentDidMount() {
        const url1 = `${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_all_categories.php`;
        const response1 = await fetch(url1);
        const data1 = await response1.json();
        this.setState({categories: data1, loading: false});

        // const url = `http://localhost/api-shullani/manufacturer/get_all_manufacturers.php`;
        // const response = await fetch(url);
        // const data = await response.json();
        // this.setState({manufacturers: data, loading: false});
    }

    handleChange(e) {
        let checkId = e.target.value;
        // checkId = checkId.charAt(0).toLowerCase() + checkId.slice(1);
        // checkId = checkId.replace(/\s/g, '');
        if (e.target.name === "category") {
            console.log(checkId);
            fetch(`${process.env.REACT_APP_API_SERVER_IP}manufacturer/get_category_manufacturers.php?category='${checkId}'`)
                .then(response => response.json())
                .then(data => {
                    console.log("test:" + data);
                    if (data.length > 0) {
                        this.setState({
                            manufacturers: data,
                            // collections: [{"collection": "Select Collection"}],
                        })
                    } else
                        this.setState({
                            selectedManufacturer: ""
                        })
                });
            console.log(this.state.manufacturers);
            this.selectDefaultManufacturer();
        } else if (e.target.name === "mname") {
            console.log("manufacturer");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_manufacturer_collections.php?mname=${e.target.value}`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        this.setState({
                            collections: data
                        })
                    } else {
                        this.setState({
                            collections: [],
                            selectedCollection: ""
                        })
                    }
                })
        } else if (e.target.name === "name") {
            console.log("name");
            fetch(`${process.env.REACT_APP_API_SERVER_IP}collection/get_collection_name_availability.php?name='${e.target.value}'`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        this.setState({
                            exists: true,
                            buttonDisabled: true,
                            btnStyle: 'btn btn-md btn-secondary'
                        })
                    } else
                        this.setState({
                            exists: false,
                            buttonDisabled: false,
                            btnStyle: 'btn btn-md btn-success'
                        })
                })
        } else {
            console.log("something is wrong EVENT HANDLER");
        }
    }

    render() {
        if (this.state.loading)
            return <div className="lds-hourglass"></div>
        if (localStorage.getItem('user') == null) {
            return (<Redirect to={'/login'}/>)
        }
        return (
            <div className="container">
                <br/>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => window.history.back()}
                    >
                        ← Back
                    </button>
                </div>
                <br/>
                <div className="card mx-xl-5">
                    <div className="card-body">
                        <p className="h4 text-center py-4">Add a new collection</p>
                        <form
                            action={process.env.REACT_APP_API_SERVER_IP + "collection/create_collection.php"}
                            method="POST"
                        >

                            <label /*htmlFor="category"*/ className="grey-text font-weight-light">
                                Category
                            </label>
                            <br/>
                            <select name='category' defaultValue='' onChange={this.handleChange.bind(this)}>
                                <option value=""  disabled hidden>Select Category</option>
                                {
                                    this.state.categories.map(m => {
                                        return <option value={m.category} name='category' key={m.category}
                                        >{m.category} </option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>


                            <label className="grey-text font-weight-light">
                                Manufacturer Name
                            </label>
                            <br/>
                            <select id="manufacturerSelect" name='mname' defaultValue='' onChange={this.handleChange.bind(this)}>
                                <option value='' disabled hidden>Select Manufacturer</option>
                                {
                                    this.state.manufacturers.map(m => {
                                        return <option value={m.mname} name='mname' key={m.mname}
                                                       required>{m.mname}</option>
                                    })
                                }
                            </select>
                            <br/>
                            <br/>

                            <label htmlFor="name" className="grey-text font-weight-light">
                                Collection Name
                            </label>
                            {this.state.exists ? <span style={{color: 'red', float: 'right'}}>* This collection name already
                  exists!</span> : null}

                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Collection Name"
                                onChange={this.handleChange.bind(this)}
                                required
                            />
                            <br/>
                            <label
                                htmlFor="description"
                                className="grey-text font-weight-light"
                            >
                                Description
                            </label>
                            <input
                                className="form-control"
                                id="description"
                                name="description"
                                placeholder="Description"
                            />
                            <br/>
                            <label
                                htmlFor="subDescription"
                                className="grey-text font-weight-light"
                            >
                                SubDescription
                            </label>
                            <input
                                className="form-control"
                                id="subDescription"
                                name="subDescription"
                                placeholder="SubDescription"
                            />
                            <br/>

                            <label htmlFor="colours" className="grey-text font-weight-light">
                                Colours
                            </label>
                            <input
                                className="form-control"
                                id="colours"
                                name="colours"
                                placeholder="Colours"
                            />
                            <br/>

                            <label htmlFor="surfaces" className="grey-text font-weight-light">
                                Surfaces
                            </label>
                            <input
                                className="form-control"
                                id="surfaces"
                                name="surfaces"
                                placeholder="Surfaces"
                            />
                            <br/>

                            <label htmlFor="Sizes" className="grey-text font-weight-light">
                                Sizes
                            </label>
                            <input
                                className="form-control"
                                id="sizes"
                                name="sizes"
                                placeholder="Sizes"
                            />
                            <br/>

                            <label htmlFor="characteristics" className="grey-text font-weight-light">
                                Characteristics
                            </label>
                            <input
                                className="form-control"
                                id="characteristics"
                                name="characteristics"
                                placeholder="Characteristics"
                            />
                            <br/>

                            <label htmlFor="structures" className="grey-text font-weight-light">
                                Structures
                            </label>
                            <input
                                className="form-control"
                                id="structures"
                                name="structures"
                                placeholder="Structures"
                            />
                            <br/>

                            <label htmlFor="effects" className="grey-text font-weight-light">
                                Effects
                            </label>
                            <input
                                className="form-control"
                                id="effects"
                                name="effects"
                                placeholder="Effects"
                            />
                            <br/>

                            <label htmlFor="type" className="grey-text font-weight-light">
                                Type
                            </label>
                            <input
                                className="form-control"
                                id="type"
                                name="type"
                                placeholder="Type"
                            />
                            <br/>

                            <label htmlFor="room" className="grey-text font-weight-light">
                                Room
                            </label>
                            <input
                                className="form-control"
                                id="room"
                                name="room"
                                placeholder="Room"
                            />
                            <br/>

                            <label htmlFor="quality" className="grey-text font-weight-light">
                                Quality
                            </label>
                            <input
                                className="form-control"
                                id="quality"
                                name="quality"
                                placeholder="quality"
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image1 URL
                            </label>
                            <input
                                className="form-control"
                                id="img1URL"
                                name="image1"
                                placeholder="imgURL"
                                required
                            />
                            <br/>

                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image2 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image2"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image3 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image3"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image4 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image4"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image5 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image5"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image6 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image6"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image7 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image7"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image8 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image8"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image9 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image9"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image10 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image10"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image11 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image11"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image12 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image12"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image13 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image13"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image14 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image14"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Image15 URL
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="image15"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image1 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage1"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image2 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage2"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image3 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage3"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image4 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage4"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image5 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage5"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image6 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage6"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image7 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage7"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image8 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage8"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image9 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage9"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image10 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage10"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image11 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage11"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image12 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage12"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image13 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage13"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image14 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage14"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image15 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage15"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image16 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage16"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image17 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage17"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image18 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage18"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image19 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage19"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image20 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage20"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image21 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage21"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image22 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage22"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image23 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage23"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image24 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage24"
                                placeholder="imgURL"
                            />
                            <br/>
                            <label htmlFor="imgURL" className="grey-text font-weight-light">
                                Tiles - Image25 URL,color
                            </label>
                            <input
                                className="form-control"
                                id="imgURL"
                                name="colorImage25"
                                placeholder="imgURL"
                            />
                            <br/>


                            <div className="text-center py-4 mt-3">
                                <button type="submit" className={this.state.btnStyle}
                                        disabled={this.state.buttonDisabled}>
                                    Add collection to database{' '}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
